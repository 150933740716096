import React, { Fragment } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Create, SimpleForm, required, ReferenceInput, AutocompleteInput } from "react-admin";
import TextInput from "../../components/CustomTextInput";

const CreateParty = (props) => {
    return (
        <Create className="my-2" actions={Fragment} {...props}>
            <SimpleForm
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        e.preventDefault();
                        document.activeElement.blur();
                    }
                }}
            >
                <Grid container direction="row" justifyContent="center" columnSpacing={6}>
                    <Grid item xs={6} container direction="column">
                        <Typography style={{ fontWeight: "600" }} variant="h5">
                            Broker details
                        </Typography>
                        <TextInput label="Name" source="name" validate={[required()]} />
                        <TextInput label="Address" minRows={4} multiline source="address" />
                        <ReferenceInput source="area" reference="areas">
                            <Grid item container direction="row" alignItems="center">
                                <Grid item xs={4}>
                                    <Typography>Area</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <AutocompleteInput label={false} optionText="name" />
                                </Grid>
                            </Grid>
                        </ReferenceInput>
                        <TextInput source="phone" type="number" label="Phone" />
                        <TextInput source="email" label="Email" />
                        <TextInput source="st_number" label="ST Number" />
                        <TextInput source="cst_number" label="CST Number" />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    );
};

export default CreateParty;
