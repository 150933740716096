import moment from "moment"
import getDueDates from "./getDueDates"

/*
    paymentsData: Array of receipts
    {
        paid_amount: number, total amount paid including all default amounts
        receipt_amount: number, total amount paid excluding default amount
        date: date, date of payment
        default_amount: number, default amount paid
    }
    accountData: details regarding the account
    {
        due_start: date,
        number_of_dues: number,
        due_amount: number,
        default_interest: number,
    }
*/
const buildDuesTable = ({ paymentsData, accountData }) => {
    if (!paymentsData || !accountData) return {
        table: [],
        defaultInterest: 0,
        defaultReceived: 0,
        totalPendingAmount: 0,
        lastAllClosedDefaultsDate: null,
    }

    const table = []
    // start from due_start date and add number_of_dues
    const dueDates = getDueDates({
        numberOfDues: accountData?.number_of_dues || 0,
        dueStart: accountData.due_start,
    })

    console.log({
        paymentsData
    })

    // for each due date, get the total amount due
    for (let i = 0; i < dueDates.length; i++) {
        table.push({
            dueNo: i + 1,
            dueDate: dueDates[i],
            dueAmount: accountData.due_amount,
            pendingDueAmount: accountData.due_amount,
            dueReceived: 0,
            defaultReceived: 0,
            defaultDays: 0,
            OD: 0,
        })
    }

    let aggregateDefaultInterest = 0
    let aggregateDefaultReceived = 0
    let runningDefaultAmount = 0
    let runningReceiptIndex = 0
    let runningReceiptAmount = paymentsData[runningReceiptIndex]?.receipt_amount || 0
    let receiptsLoopDone = false
    let lastAllClosedDefaultsDate = null

    for (let i = 0; i < table.length; i++) {
        while (runningReceiptAmount <= 0) {
            if (runningReceiptIndex >= paymentsData.length - 1) {
                receiptsLoopDone = true
                break
            }

            runningReceiptIndex++
            runningReceiptAmount = paymentsData[runningReceiptIndex].receipt_amount
        }

        const pendingDueAmount = table[i].pendingDueAmount
        // get default interest based on date
        let numberOfDueDays = moment(receiptsLoopDone ? Date.now() : paymentsData[runningReceiptIndex].date).diff(moment(table[i].dueDate), "days")
        numberOfDueDays = Math.max(numberOfDueDays, 0)

        const defaultInterest = Math.ceil((numberOfDueDays / (100 * 360)) * accountData.default_interest * pendingDueAmount)

        table[i].isCurrentReceipt = !!paymentsData[runningReceiptIndex]?.isCurrentReceipt

        if (!receiptsLoopDone) {
            if (paymentsData[runningReceiptIndex].close_defaults) {
                aggregateDefaultInterest = 0
                aggregateDefaultReceived = 0
                table[i].defaultReceived = 0
                lastAllClosedDefaultsDate = moment(paymentsData[runningReceiptIndex].date)
            } else if (paymentsData[runningReceiptIndex].default_amount) {

                aggregateDefaultInterest -= paymentsData[runningReceiptIndex].default_amount
                aggregateDefaultReceived += paymentsData[runningReceiptIndex].default_amount
                table[i].defaultReceived += paymentsData[runningReceiptIndex].default_amount
            }
        }

        if (defaultInterest > 0) {
            // check if all defaults closed date is before current due date
            if (lastAllClosedDefaultsDate && lastAllClosedDefaultsDate.isAfter(table[i].dueDate)) {
            } else {
                aggregateDefaultInterest += defaultInterest
                runningDefaultAmount += defaultInterest
            }
        }

        if (runningReceiptAmount >= pendingDueAmount) {
            runningReceiptAmount -= pendingDueAmount
            table[i].pendingDueAmount = 0
            table[i].receiptDate = paymentsData[runningReceiptIndex].date
            table[i].receiptNumber = paymentsData[runningReceiptIndex].receipt_number
            table[i].defaultAmount = runningDefaultAmount
            table[i].defaultDays += Math.max(numberOfDueDays, 0)


            runningDefaultAmount = 0
        } else {
            table[i].pendingDueAmount -= runningReceiptAmount
            table[i].defaultDays += Math.max(numberOfDueDays, 0)

            // handle last index
            if (runningReceiptIndex == paymentsData.length - 1 && !receiptsLoopDone) {
                table[i].defaultDays += Math.max(moment(Date.now()).diff(moment(paymentsData[runningReceiptIndex].date), "days"), 0)
            }

            if (runningReceiptIndex < paymentsData.length - 1) {
                i--
            }

            runningReceiptAmount = 0
        }

        if (i >= 0) {

            table[i].OD = table[i].defaultDays * table[i].dueAmount * accountData.default_interest / (100 * 360)
        }

    }



    return {
        table,
        defaultInterest: aggregateDefaultInterest,
        defaultReceived: aggregateDefaultReceived,
        totalPendingAmount: table.reduce((acc, curr) => acc + curr.pendingDueAmount, 0),
        lastAllClosedDefaultsDate,
    }
}

export default buildDuesTable
