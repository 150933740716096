import { useEffect } from "react"

const useClearFilter = (listName) => {
    useEffect(() => {
        return () => {
            console.log("clearing..")

            // delete RaStore.receipts.listParams in localStorage
            localStorage.removeItem(`RaStore.${listName}.listParams`)
        }
    }, [])
}

export default useClearFilter
