import React, { useEffect, useState, Fragment, useLayoutEffect } from "react";
import { useQuery } from "react-query";
import CommonTextField from "../../components/CommonTextField";
import CircularProgress from "@mui/material/CircularProgress";
import { Create, Edit, SimpleForm, AutocompleteInput, ReferenceInput, useCreate } from "react-admin";
import { useFormContext, useWatch, FormProvider } from "react-hook-form";
import SaveButtonToolbar from "../../components/SaveButtonToolbar";
import moment from "moment";
import KEYS, { getConstantAPI, getPartyAPI } from "../../api";
import { HandLoan } from ".";

const Inputs = ({ isCreate }) => {
    const form = useFormContext();
    const { getValues, setValue } = form;

    const date = useWatch({ name: "created_at" });
    const loanAmount = useWatch({ name: "loan_amount" });
    const numberOfDues = useWatch({ name: "number_of_dues" });
    const interestPercentage = useWatch({ name: "interest_percentage" });
    const difference_in_amount = useWatch({ name: "difference_in_amount" });
    const account_number_with_ledger_number = useWatch({
        name: "account_number_with_ledger_number",
    });
    const [handLoans, setHandLoans] = useState(0);
    const [handLoan, setTotalHandLoan] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [commissionPercentage, setCommissionPercentage] = useState(0);

    useEffect(() => {
        setValue("difference_in_amount", ((commissionPercentage * (numberOfDues / 12) * loanAmount) / 100).toFixed(0));
    }, [commissionPercentage, loanAmount, numberOfDues]);

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            // check if current element name is address

            const address = document.querySelector("#address");
            // insert address after index 2 in inputs
            const inputs = document.querySelectorAll("input");
            let index = Array.prototype.indexOf.call(inputs, event.target);

            // use enter key for form navigation instead of tab
            if (index == 3) {
                address.focus();
            } else {
                if (event.target.name === "address") index = 3;

                if (index < inputs.length - 1) {
                    inputs[index + 1].focus();
                }
            }
        }
    };

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    useEffect(() => {
        if (loanAmount && difference_in_amount) {
            const totalYears = numberOfDues / 12;
            // interestPercentage is for 1 year

            const totalInterest = (+loanAmount * +interestPercentage * +totalYears) / 100;
            const totalAmount = +loanAmount + +totalInterest;
            const amountPerMonth = +totalAmount / +numberOfDues;

            setValue("due_amount", Math.ceil(amountPerMonth));
            setValue("hand_loans", handLoans);
            setTotalAmount(loanAmount - difference_in_amount - handLoan);
        }
    }, [loanAmount, numberOfDues, interestPercentage, handLoan, handLoans, difference_in_amount]);

    useLayoutEffect(() => {
        // add one month to date and set it to due_start
        if (date) {
            setValue("due_start", moment(date).add(1, "month").format("YYYY-MM-DD"));
        } else {
            setValue("created_at", moment(new Date()).format("YYYY-MM-DD"));
        }
    }, [date]);

    const [create] = useCreate(undefined, undefined, {
        onSuccess: (data) => {
            setValue("area", data.name);
        },
    });

    const { data: ledgerNumberData, loading: getConstantLoading } = useQuery([KEYS.GET_CONSTANT, "ledger_number"], () =>
        getConstantAPI({
            queryParams: {
                label: "eq.ledger_number",
            },
        })
    );
    const [ledger_number, account_number] = account_number_with_ledger_number?.split("/") || [];
    const ledgerNumber = ledger_number || (ledgerNumberData && ledgerNumberData.length && ledgerNumberData[0].value) || -1;

    const { data: partyData, loading: getPartyLoading } = useQuery(
        [KEYS.GET_PARTY, "last_account_number", ledgerNumber],
        () =>
            getPartyAPI({
                queryParams: {
                    order: "account_number.desc.nullslast",
                    limit: 1,
                    ledger_number: `eq.${ledgerNumber}`,
                },
            }),
        {
            enabled: !getConstantLoading,
            cacheTime: 0,
            staleTime: 0,
        }
    );

    const newAccountNumber = account_number || ((partyData && partyData.length && partyData[0].account_number) || 0) + 1;

    useEffect(() => {
        setValue("account_number", newAccountNumber);
        setValue("ledger_number", +ledgerNumber);
    }, [newAccountNumber, ledgerNumber]);

    if (getConstantLoading || getPartyLoading || !partyData || !ledgerNumberData) return <CircularProgress />;

    return (
        <FormProvider {...form}>
            <div className="flex w-full  px-6 py-3 bg-background gap-12">
                <div className="flex w-1/2 flex-col">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <p className="text-sm mr-2 text-primary font-semibold w-full whitespace-nowrap">A/c No.</p>
                            <CommonTextField
                                type="text"
                                className="w-40"
                                placeholder="Ledger No."
                                defaultValue={ledgerNumber}
                                register={form.register("ledger_number")}
                            />
                            <CommonTextField
                                type="text"
                                placeholder="Account No."
                                defaultValue={newAccountNumber}
                                register={form.register("account_number")}
                            />
                        </div>
                        <div className="flex items-center ">
                            <p className="text-sm mr-2  capitalize text-primary font-semibold">Date</p>
                            <CommonTextField
                                type="date"
                                className="cursor-pointer"
                                defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                                register={form.register("created_at")}
                            />
                        </div>
                    </div>
                    <div className="flex items-center justify-start mt-2">
                        <p className="text-base mr-2  capitalize text-secondary font-semibold">Party Details</p>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Name</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField type="text" className="w-full" placeholder="Name" register={form.register("name")} />
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Address</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField type="text" multiline className="w-full" placeholder="Address" register={form.register("address")} />
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Land Mark</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField type="text" className="w-full" placeholder="Land Mark" register={form.register("landmark")} />
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Phone (Office)</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField type="number" className="w-full" placeholder="Phone (Office)" register={form.register("office_phone")} />
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Phone (Residential)</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField
                                type="number"
                                className="w-full"
                                placeholder="Phone (Residential)"
                                register={form.register("resedential_phone")}
                            />
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Mobile</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField type="number" className="w-full" placeholder="Mobile" register={form.register("mobile")} />
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Auto Stand</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField type="text" className="w-full" placeholder="Auto Stand" register={form.register("auto_stand")} />
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Area</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <ReferenceInput source="area" reference="areas" allowEmpty>
                                <AutocompleteInput
                                    label={false}
                                    limitChoicesToValue={true}
                                    optionText="name"
                                    optionValue="name"
                                    clearOnBlur={false}
                                    className={`auto-complete w-full`}
                                    id="area"
                                    filterToQuery={(searchText) => ({ "name@ilike": `%${searchText}%` })}
                                    onBlur={() => {
                                        const value = document.getElementById("area").value;
                                        const area = getValues("area");
                                        if (value && value !== area) {
                                            create("areas", {
                                                data: {
                                                    name: value.toUpperCase(),
                                                },
                                            });
                                        }
                                    }}
                                />
                            </ReferenceInput>
                        </div>
                    </div>
                    <div className="flex items-center justify-start mt-2">
                        <p className="text-base mr-2  capitalize text-secondary font-semibold">Guarantor Details</p>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Name</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField type="text" className="w-full" placeholder="Name" register={form.register("guarentor_name")} />
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Address</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField
                                type="text"
                                multiline
                                className="w-full"
                                placeholder="Address"
                                register={form.register("guarentor_address")}
                            />
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Broker</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <ReferenceInput source="broker" reference="brokers">
                                <AutocompleteInput
                                    className={`auto-complete w-full`}
                                    label={false}
                                    helperText={false}
                                    optionText="name"
                                    filterToQuery={(searchText) => ({ "name@ilike": `%${searchText}%` })}
                                />
                            </ReferenceInput>
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Vehicle</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <ReferenceInput source="vehicle" reference="vehicles">
                                <AutocompleteInput className={`auto-complete w-full`} label={false} helperText={false} optionText="label" />
                            </ReferenceInput>
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Through</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <AutocompleteInput
                                className={`auto-complete w-full`}
                                fullWidth
                                label={false}
                                helperText={false}
                                source="through"
                                choices={[
                                    { id: "dealer", name: "Dealer" },
                                    { id: "direct", name: "Direct" },
                                ]}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex w-1/2 flex-col">
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-base mr-2  capitalize text-secondary font-semibold">Due Details</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <p className="text-base mr-2 w-full text-center capitalize text-primary font-semibold">Monthly</p>
                        </div>
                    </div>
                    <div className="flex items-center justify-start mt-2"></div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Due Start</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField
                                type="date"
                                defaultValue={moment(new Date()).add(1, "month").format("YYYY-MM-DD")}
                                className="w-full"
                                placeholder="Name"
                                register={form.register("due_start")}
                            />
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Loan Amount</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField
                                type="number"
                                min={0}
                                className="w-full"
                                placeholder="Loan Amount"
                                register={form.register("loan_amount")}
                            />
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Number of Dues</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField
                                type="number"
                                min={0}
                                className="w-full"
                                placeholder="Number of Dues"
                                register={form.register("number_of_dues")}
                            />
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Interest Percentage</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField
                                type="number"
                                min={0}
                                className="w-full"
                                placeholder="Interest Percentage"
                                register={form.register("interest_percentage")}
                            />
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Due Amount</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField
                                type="number"
                                min={0}
                                className="w-full"
                                placeholder="Due Amount"
                                register={form.register("due_amount")}
                            />
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Default Interest</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField
                                type="number"
                                min={0}
                                className="w-full"
                                defaultValue={36}
                                placeholder="Default Interest"
                                register={form.register("default_interest")}
                            />
                        </div>
                    </div>
                    {isCreate && (
                        <Fragment>
                            <div className="flex items-center justify-start mt-2">
                                <p className="text-base mr-2  capitalize text-secondary font-semibold">Receipt Details</p>
                            </div>
                            <div className="flex items-center mb-0.5">
                                <div className="flex items-center w-1/3">
                                    <p className="text-sm  text-primary font-semibold w-full">Commission</p>
                                </div>
                                <div className="flex items-center w-2/3">
                                    <CommonTextField
                                        type="number"
                                        min={0}
                                        className="w-1/2"
                                        placeholder="Percentage"
                                        value={commissionPercentage}
                                        onChange={(e) => setCommissionPercentage(e.target.value)}
                                    />
                                    <CommonTextField
                                        type="number"
                                        min={0}
                                        className="w-1/2"
                                        disabled={!isCreate}
                                        defaultValue={0}
                                        placeholder="Amount"
                                        register={form.register("difference_in_amount")}
                                    />
                                </div>
                            </div>
                            <HandLoan isCreate={isCreate} setTotalHandLoan={setTotalHandLoan} setTotalHandLoans={setHandLoans} />
                            <div className="flex items-center mb-0.5 mt-5">
                                <p className="text-base  text-primary font-semibold w-full">
                                    Total Amount: <strong>{isNaN(totalAmount) ? "0" : totalAmount}</strong>
                                </p>
                            </div>
                        </Fragment>
                    )}
                </div>
            </div>
        </FormProvider>
    );
};

const PartyForm = ({ isCreate, ...props }) => {
    const Wrapper = isCreate ? Create : Edit;

    const additionalProps = isCreate
        ? {}
        : {
              toolbar: <SaveButtonToolbar />,
          };

    const transform = (data) => {
        // delete id if it is present
        if (data.id) {
            delete data.id;
        }

        return {
            ...data,
        };
    };

    return (
        <Wrapper {...props} className="my-2" actions={false} redirect="list" transform={transform}>
            <SimpleForm
                warnWhenUnsavedChanges
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        e.preventDefault();

                        // @ts-ignore
                        document.activeElement.blur();
                    }
                }}
                {...additionalProps}
            >
                <Inputs isCreate={isCreate} />
            </SimpleForm>
        </Wrapper>
    );
};

export default PartyForm;
