import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import "./VehiclesForm.styles.scss";
import { Create, SimpleForm, TextInput } from "react-admin";
import { Fragment } from "react";
const VehiclesForm = ({ ...props }) => {
    const Wrapper = Create;
    return (
        <Wrapper actions={Fragment} className="my-2" {...props}>
            <SimpleForm>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Typography variant="h5">Vehicles Form</Typography>
                    <TextInput fullWidth source="label" />
                </Grid>
            </SimpleForm>
        </Wrapper>
    );
};

export default VehiclesForm;
