import React, { Fragment } from "react";
import { Show, SimpleShowLayout, TextField, BooleanField, Labeled, ReferenceField, FunctionField } from "react-admin";
import { Grid, Typography } from "@mui/material";

const fields = [
    {
        name: "account_number",
        type: "text",
    },
    {
        name: "name",
        type: "text",
    },
    {
        name: "rc_book",
        type: "boolean",
    },
    {
        name: "rc_owner",
        type: "text",
    },
    {
        source: "rto_office",
        type: "reference",
        reference: "rto_offices",
        label: "RTO Office",
        subField: "name",
        name: "rto_office",
    },
    {
        name: "registration_number",
        type: "text",
    },
    {
        name: "tax_expiry",
        type: "date",
    },
    {
        name: "permit_expiry",
        type: "date",
    },
    {
        name: "fc_expiry",
        type: "date",
    },
    {
        name: "insurance",
        type: "boolean",
    },
    {
        name: "insurance_company",
        type: "text",
    },
    {
        name: "insurance_policy",
        type: "text",
    },
    {
        name: "insurance_expiry_date",
        type: "date",
    },
    {
        name: "invoice_copy",
        type: "boolean",
    },
    {
        name: "address_proof",
        type: "boolean",
    },
    {
        name: "photo",
        type: "boolean",
    },
    {
        name: "remarks",
        type: "text",
    },
    {
        name: "photo_path",
        type: "text",
    },

    {
        name: "chassis_number",
        type: "text",
    },
    {
        name: "engine_number",
        type: "text",
    },
    {
        name: "key_number",
        type: "text",
    },
];

const ShowDocument = () => {
    return (
        <Show actions={Fragment} className="my-2" title="Document details">
            <SimpleShowLayout>
                <Typography variant="h5">Document Details</Typography>
                <Grid container style={{ width: "100%" }} direction="row" justifyContent="center" rowSpacing={2}>
                    {fields.map((field, index) => {
                        return (
                            <Grid item xs={4}>
                                <Labeled
                                    label={
                                        // convert to title case
                                        field.name
                                            .split("_")
                                            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                            .join(" ")
                                    }
                                >
                                    {field.name === "account_number" || field.name === "name" || field.reference ? (
                                        <ReferenceField source={field.source || "account_number"} reference={field.reference || "parties"}>
                                            <FunctionField
                                                render={(record) => {
                                                    return (
                                                        record &&
                                                        `${
                                                            record[
                                                                field.name === "account_number"
                                                                    ? "account_number_with_ledger_number"
                                                                    : field.subField || field.name
                                                            ]
                                                        }`
                                                    );
                                                }}
                                            />
                                        </ReferenceField>
                                    ) : field.type === "text" ? (
                                        <TextField source={field.name} />
                                    ) : (
                                        <BooleanField source={field.name} />
                                    )}
                                </Labeled>
                            </Grid>
                        );
                    })}
                </Grid>
            </SimpleShowLayout>
        </Show>
    );
};

export default ShowDocument;
