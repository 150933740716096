import React from "react";
import { useListContext, CreateButton } from "react-admin";
import { useForm, FormProvider } from "react-hook-form";

const PartiesFilter = () => {
    const { filterValues, setFilters } = useListContext();

    const form = useForm({
        defaultValues: filterValues,
    });

    const resetFilter = () => {
        form.reset();
        setFilters({}, []);
    };

    const onSubmit = (values) => {
        const [ledgerNumber, accountNumber] = values.account_number_with_ledger_number.split("/");

        setFilters(
            {
                ...filterValues,
                account_number: accountNumber,
                ledger_number: ledgerNumber,
                "registration_number_from_parties@ilike": values.registration_number,
                "name@ilike": values.name,
                "created_at@gte": values.created_after,
                "created_at@lte": values.created_before,
            },
            []
        );
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="w-full my-4 bg-background p-4">
                <div className="flex flex-row w-full">
                    <div className="flex w-2/5 flex-col mr-4">
                        <div className="flex justify-between items-center mb-1">
                            <p className="text-sm  capitalize text-primary font-semibold">Party Name</p>
                            <input
                                type="text"
                                className="border w-4/6 h-8 rounded-sm border-gray-200 text-sm outline-none bg-white px-4 py-2"
                                placeholder="Party Name"
                                {...form.register("name")}
                            />
                        </div>
                        <div className="flex justify-between mb-1 items-center">
                            <p className="text-sm  capitalize text-primary font-semibold">Reg. No.</p>
                            <input
                                type="text"
                                className="border w-4/6 h-8 rounded-sm border-gray-200 text-sm outline-none bg-white px-4 py-2"
                                placeholder="Registration No."
                                {...form.register("registration_number")}
                            />
                        </div>
                        <div className="flex justify-between mb-1 items-center">
                            <p className="text-sm  capitalize text-primary font-semibold">Acc. No.</p>
                            <input
                                type="text"
                                className="border w-4/6 h-8 rounded-sm border-gray-200 text-sm outline-none bg-white px-4 py-2"
                                placeholder="Account No."
                                {...form.register("account_number_with_ledger_number")}
                            />
                        </div>
                    </div>
                    <div className="flex w-2/5 flex-col mx-4">
                        <div className="flex  justify-between mb-1 items-center">
                            <p className="text-sm  capitalize text-primary font-semibold">From</p>
                            <input
                                type="date"
                                className="cursor-pointer border w-4/6 h-8 rounded-sm border-gray-200 text-sm outline-none bg-white px-4 py-2"
                                {...form.register("created_after")}
                            />
                        </div>
                        <div className="flex justify-between mb-1 items-center">
                            <p className="text-sm  capitalize text-primary font-semibold">To</p>
                            <input
                                type="date"
                                className="cursor-pointer border w-4/6 h-8 rounded-sm border-gray-200 text-sm outline-none bg-white px-4 py-2"
                                {...form.register("created_before")}
                            />
                        </div>
                    </div>
                    <div className="flex w-1/5 flex-col center">
                        <button type="submit" className="text-sm w-full mb-1 h-8 bg-white px-4  py-1 text-black rounded-sm border border-gray-200">
                            Search Now
                        </button>
                        <button
                            className="text-sm bg-white w-full text-black h-8 px-4 mb-1  py-1 rounded-sm border border-gray-200"
                            onClick={resetFilter}
                        >
                            New Search
                        </button>
                        <CreateButton
                            disableRipple
                            className="!text-sm !flex !items-center !capitalize !justify-center !w-full !mb-1 !h-8 !bg-white px-4  py-1 !text-black !rounded-sm !border !border-gray-200"
                        />
                    </div>
                </div>
            </form>
        </FormProvider>
    );
};

export default PartiesFilter;
