import React, { useEffect, useMemo, Fragment } from "react";
import { Show, SimpleShowLayout, TextField, ReferenceField, FunctionField, useShowContext } from "react-admin";
import { Grid, Typography } from "@mui/material";
import ShowPartyTable from "./ShowPartyTable";
import KEYS, { getReceiptAPI } from "../../api";
import HandloanTable from "./HandloanTable";
import { useQuery } from "react-query";
import { formatDate, buildDuesTable } from "../../utils";
import "./ShowParty.styles.scss";
import moment from "moment";
import { number } from "prop-types";

const PARTY_DETAILS = [
    {
        source: "name",
        type: "text",
    },
    {
        source: "address",
        type: "text",
    },
    // {
    //     source: "landmark",
    //     type: "text",
    // },
    {
        source: "office_phone",
        type: "text",
    },
    // {
    //     source: "resedential_phone",
    //     type: "text",
    // },
    // {
    //     source: "mobile",
    //     type: "text",
    // },
    // {
    //     source: "auto_stand",
    //     type: "text",
    // },
    {
        source: "area",
        type: "text",
    },
];

const Layout = () => {
    const { record } = useShowContext();

    const { data: paymentsData, refetch: getPaymentsData } = useQuery(
        [KEYS.GET_RECEIPT, record?.account_number_with_ledger_number],
        () =>
            getReceiptAPI({
                queryParams: {
                    account_number: `eq.${record?.account_number_with_ledger_number}`,
                    order: "id.asc.nullslast",
                    is_deleted: "eq.false",
                },
            }),
        {
            enabled: false,
        }
    );

    useEffect(() => {
        if (record?.account_number_with_ledger_number) {
            getPaymentsData({
                accountNumber: record?.account_number_with_ledger_number,
            });
        }
    }, [record?.account_number_with_ledger_number]);

    const { table, defaultInterest, defaultReceived } = useMemo(() => {
        if (!paymentsData)
            return {
                table: [],
                defaultInterest: 0,
                defaultReceived: 0,
                totalPendingAmount: 0,
            };

        return buildDuesTable({
            paymentsData: paymentsData?.length
                ? paymentsData
                : [
                      {
                          receipt_amount: 0,
                      },
                  ],
            accountData: record,
        });
    }, [paymentsData]);

    const totalDues = table?.length || 0;
    const totalDuesReceived = table?.filter(({ pendingDueAmount }) => pendingDueAmount <= 0)?.length || 0;
    const totalPendingDues = totalDues - totalDuesReceived;

    return (
        <div className="!font-bold">
            <Grid container>
                <Grid xs={4} item container className="text-blue-800">
                    <Grid
                        item
                        xs={12}
                        container
                        alignItems="center"
                        style={{
                            color: "maroon",
                        }}
                    >
                        <Grid item xs={"auto"}>
                            <Typography variant="p">A/c No </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <p className="!font-semibold">
                                :&nbsp; <TextField source="account_number_with_ledger_number" label={false} />
                            </p>
                        </Grid>
                    </Grid>
                    {PARTY_DETAILS.map((item, index) => (
                        <Grid item xs={12} key={index}>
                            <FunctionField
                                render={(record) => {
                                    return record[item.source]?.toUpperCase();
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>
                <Grid item xs={4} container>
                    <Grid item xs={12} container alignItems="center">
                        <Grid item xs={4}>
                            <Typography variant="p" className="label">
                                Loan Date
                            </Typography>
                        </Grid>
                        <Grid item xs={6} className="text-blue-800">
                            :&nbsp;
                            <FunctionField
                                render={(record) => {
                                    return moment(record["created_at"]?.substr(0, 10)).format("DD-MM-YYYY");
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container alignItems="center">
                        <Grid item xs={4}>
                            <Typography variant="p" className="label">
                                Due Date{" "}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} className="text-blue-800">
                            :&nbsp;
                            <FunctionField
                                render={(record) => {
                                    return moment(record["due_start"]?.substr(0, 10)).format("DD-MM-YYYY");
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container alignItems="center">
                        <Grid item xs={4}>
                            <Typography variant="p" className="label">
                                Loan Amt{" "}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} className="text-blue-800">
                            :&nbsp;
                            <TextField source="loan_amount" label={false} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container alignItems="center">
                        <Grid item xs={4}>
                            <Typography variant="p" className="label">
                                Hire Charge{" "}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} className="text-blue-800">
                            :&nbsp;
                            <FunctionField
                                render={(record) => {
                                    const interestPercentage = record["interest_percentage"] || 0;
                                    const loanAmount = record["loan_amount"] || 0;
                                    const numberOfDues = record["number_of_dues"] || 0;

                                    return (((loanAmount * interestPercentage * numberOfDues) / (100 * 12 * numberOfDues)) * numberOfDues).toFixed(2);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container alignItems="center">
                        <Grid item xs={4}>
                            <Typography variant="p" className="label">
                                Total Dues{" "}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} className="text-blue-800">
                            :&nbsp;
                            <TextField source="number_of_dues" label={false} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container alignItems="center">
                        <Grid item xs={4}>
                            <Typography variant="p" className="label">
                                Due Amt{" "}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} className="text-blue-800">
                            :&nbsp;
                            <TextField source="due_amount" label={false} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container alignItems="center">
                        <Grid item xs={4}>
                            <Typography variant="p" className="label">
                                Interest (%){" "}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} className="text-blue-800">
                            :&nbsp;
                            <TextField source="interest_percentage" label={false} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container alignItems="center">
                        <Grid item xs={4}>
                            <Typography variant="p" className="label">
                                Adv Int{" "}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} className="text-blue-800">
                            :&nbsp;
                            <TextField source="adv_int_amt" label={false} />
                        </Grid>
                    </Grid>

                    <div className="flex w-full text-primary mt-3">
                        <p className="basis-1/3">
                            <strong className="text-sm">Total Dues</strong>
                        </p>
                        <p>: {totalDues}</p>
                    </div>
                    <div className="flex w-full text-primary">
                        <p className="basis-1/3">
                            <strong className="text-sm">Paid Dues</strong>
                        </p>
                        <p>: {totalDuesReceived}</p>
                    </div>
                    <div className="flex w-full text-primary mb-3">
                        <p className="basis-1/3">
                            <strong className="text-sm whitespace-nowrap">Pending Dues</strong>
                        </p>
                        <p>: {totalPendingDues}</p>
                    </div>
                </Grid>
                <Grid item xs={4} container>
                    <Grid item xs={12} container alignItems="center">
                        <Grid item xs={4}>
                            <Typography variant="p" className="label">
                                Broker{" "}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} className="text-blue-800">
                            :&nbsp;
                            <ReferenceField source="broker" reference="brokers">
                                <FunctionField
                                    render={(record) => {
                                        return record["name"];
                                    }}
                                />
                            </ReferenceField>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container alignItems="center">
                        <Grid item xs={4}>
                            <Typography variant="p" className="label">
                                Vehicle{" "}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} className="text-blue-800">
                            :&nbsp;
                            <ReferenceField source="vehicle" reference="vehicles">
                                <FunctionField
                                    render={(record) => {
                                        return record["label"];
                                    }}
                                />
                            </ReferenceField>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container alignItems="center">
                        <Grid item xs={4}>
                            <Typography variant="p" className="label">
                                Reg No{" "}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} className="text-blue-800">
                            :&nbsp;
                            <ReferenceField source="account_number_with_ledger_number" reference="documents">
                                <FunctionField render={(record) => `${record.registration_number}`} />
                            </ReferenceField>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container alignItems="center">
                        <Grid item xs={4} className="label">
                            <Typography variant="p">Reg Date </Typography>
                        </Grid>
                        <Grid item xs={6} className="text-blue-800">
                            :&nbsp;
                            <ReferenceField source="account_number_with_ledger_number" reference="documents">
                                <FunctionField render={(record) => `${record.date_of_registration?.substr(0, 10) || ""}`} />
                            </ReferenceField>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container alignItems="center">
                        <Grid item xs={4}>
                            <Typography variant="p" className="label">
                                Chasis No{" "}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} className="text-blue-800">
                            :&nbsp;
                            <ReferenceField source="account_number_with_ledger_number" reference="documents">
                                <FunctionField render={(record) => `${record.chassis_number}`} />
                            </ReferenceField>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container alignItems="center">
                        <Grid item xs={4}>
                            <Typography variant="p" className="label">
                                Engine No{" "}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} className="text-blue-800">
                            :&nbsp;
                            <ReferenceField source="account_number_with_ledger_number" reference="documents">
                                <FunctionField render={(record) => `${record.engine_number}`} />
                            </ReferenceField>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container alignItems="center">
                        <Grid item xs={4}>
                            <Typography variant="p" className="label">
                                Ins Date{" "}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} className="text-blue-800">
                            :&nbsp;
                            <ReferenceField source="account_number_with_ledger_number" reference="documents">
                                <FunctionField render={(record) => `${formatDate(record.insurance_expiry_date)}`} />
                            </ReferenceField>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container alignItems="center">
                        <Grid item xs={4}>
                            <Typography variant="p" className="label">
                                FC Date{" "}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} className="text-blue-800">
                            :&nbsp;
                            <ReferenceField source="account_number_with_ledger_number" reference="documents">
                                <FunctionField render={(record) => `${formatDate(record.fc_expiry)}`} />
                            </ReferenceField>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container alignItems="center">
                        <Grid item xs={4}>
                            <Typography variant="p" className="label">
                                Permit Date{" "}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} className="text-blue-800">
                            :&nbsp;
                            <ReferenceField source="account_number_with_ledger_number" reference="documents">
                                <FunctionField render={(record) => `${formatDate(record.permit_expiry)}`} />
                            </ReferenceField>
                        </Grid>
                    </Grid>
                    <div className="flex w-full text-primary mt-3 mb-3">
                        <p className="basis-1/3">
                            <strong className="text-sm">Amount Disbursed </strong>
                        </p>
                        <p>
                            : {"  "}
                            <FunctionField
                                render={(record) => {
                                    let totalHandLoanAmount = 0;
                                    const handLoans = JSON.parse(record?.hand_loans || "[]");
                                    for (let i = 0; i < handLoans.length; i++) {
                                        totalHandLoanAmount += +handLoans[i].amount;
                                    }

                                    return `${record?.loan_amount} - ${totalHandLoanAmount + record?.adv_int_amt} = ${
                                        record?.loan_amount - (totalHandLoanAmount + record?.adv_int_amt)
                                    }`;
                                }}
                            />
                        </p>
                    </div>
                    {/* <div className="flex w-full text-primary">
                        <p className="basis-1/3">
                            <strong className="text-sm">Pending Delay Interest</strong>
                        </p>
                        <p>
                            : {"  "}
                            <FunctionField render={(record) => defaultInterest} />
                        </p>
                    </div>
                    <div className="flex w-full text-primary mb-3">
                        <p className="basis-1/3">
                            <strong className="text-sm">Paid Delay Interest</strong>
                        </p>
                        <p>
                            : {"  "}
                            <FunctionField render={(record) => defaultReceived} />
                        </p>
                    </div> */}
                </Grid>
            </Grid>

            <Grid container className="gap-4">
                <Grid className="flex-grow">
                    <ShowPartyTable tableData={table} />
                </Grid>
                <Grid className="flex-grow">
                    <div className="flex-grow">
                        <FunctionField
                            render={(record) => {
                                return <HandloanTable handLoanData={record["hand_loans"]} />;
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

const ShowParty = () => {
    return (
        <Show actions={Fragment} className="my-2" title="Party Details">
            <SimpleShowLayout style={{ padding: "1rem" }} id="show-party__container">
                <Layout />
            </SimpleShowLayout>
        </Show>
    );
};

export default ShowParty;
