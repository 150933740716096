import React from "react";
import { useListContext, CreateButton } from "react-admin";
import { useForm, FormProvider } from "react-hook-form";

const BrokersListFilter = () => {
    const { filterValues, setFilters } = useListContext();

    const form = useForm({
        defaultValues: filterValues,
    });

    const resetFilter = () => {
        form.reset();
        setFilters({}, []);
    };

    const onSubmit = (values) => {
        setFilters({
            ...filterValues,
            "name@like": values.name,
        });
    };
    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="w-full my-4 bg-background p-4">
                <div className="flex flex-row w-full">
                    <div className="flex w-2/5 flex-col mr-4">
                        <div className="flex justify-between mb-1 items-center">
                            <p className="text-sm  capitalize text-primary font-semibold">Broker Name</p>
                            <input
                                type="text"
                                className="border w-4/6 h-8 rounded-sm border-gray-200 text-sm outline-none bg-white px-4 py-2"
                                placeholder="Broker Name"
                                {...form.register("name")}
                            />
                        </div>
                    </div>
                    <div className="flex w-1/5 flex-col mx-4">
                        <button type="submit" className="text-sm w-full mb-1 h-8 bg-white px-4  py-1 text-black rounded-sm border border-gray-200">
                            Search Now
                        </button>
                    </div>
                    <div className="flex w-1/5 flex-col mx-4">
                        <button
                            className="text-sm bg-white w-full text-black h-8 px-4 mb-1  py-1 rounded-sm border border-gray-200"
                            onClick={resetFilter}
                        >
                            New Search
                        </button>
                    </div>
                    <div className="flex w-1/5 flex-col center">
                        <CreateButton
                            disableRipple
                            className="!text-sm !flex !items-center !capitalize !justify-center !w-full !mb-1 !h-8 !bg-white px-4  py-1 !text-black !rounded-sm !border !border-gray-200"
                        />
                    </div>
                </div>
            </form>
        </FormProvider>
    );
};

export default BrokersListFilter;
