import { List, TextField } from "react-admin";
import SearchInput from "../../components/SearchInput";
import { useClearFilter } from "../../hooks";
import CustomDatagrid from "../../components/CustomDatagrid";

const BrokerFilters = [<SearchInput label="Broker name" variant="outlined" source="name@like" alwaysOn />];

const BrokersList = () => {
    useClearFilter("dealers");

    return (
        <List filters={BrokerFilters}>
            <CustomDatagrid bulkActionButtons={false} rowClick="edit" resource="dealers">
                <TextField source="name" />
                <TextField source="address" />
                <TextField source="phone" />
            </CustomDatagrid>
        </List>
    );
};

export default BrokersList;
