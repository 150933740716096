import { List, TextField, ReferenceField, FunctionField, EditButton } from "react-admin";
import { useClearFilter } from "../../hooks";
import moment from "moment";
import ReceiptsFilter from "./ReceiptsFilter";
import CustomDatagrid from "../../components/CustomDatagrid";

const CustomList = () => {
    return (
        <CustomDatagrid bulkActionButtons={false} rowClick="show" resource="receipts">
            <TextField source="receipt_number" label="Receipt Number" />
            <FunctionField source="receipt_date" label="Receipt Date" render={(record) => moment(record.date).format("DD/MM/YYYY")} />
            <TextField source="account_number" label="A/c No." />
            <ReferenceField label="Party Name" source="account_number" reference="parties" link="show">
                <FunctionField render={(record) => record && `${record.name}`} />
            </ReferenceField>
            <TextField source="paid_amount" label="Paid Amount" />
            <TextField source="default_amount" label="Default Amount" />
            <EditButton />
        </CustomDatagrid>
    );
};

const ReceiptList = ({ props }) => {
    useClearFilter("receipts");

    return (
        <List
            {...props}
            actions={<ReceiptsFilter />}
            filter={{
                is_deleted: false,
            }}
            filterDefaultValues={{
                "date@eq": moment().format("YYYY-MM-DD"),
            }}
            perPage={50}
        >
            <CustomList />
        </List>
    );
};

export default ReceiptList;
