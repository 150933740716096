import React from "react";
import { DateField, List, TextField, EditButton, ReferenceField, FunctionField } from "react-admin";
import PartiesFilter from "./PartiesFilter";
import { useClearFilter } from "../../hooks";
import CustomDatagrid from "../../components/CustomDatagrid";
import CreateReceiptButton from "../../components/CreateReceiptButton";

const CustomList = () => {
    return (
        <CustomDatagrid bulkActionButtons={false} rowClick="show" resource="parties">
            <CreateReceiptButton />
            <TextField label="A/c No" source="account_number_with_ledger_number" />
            <TextField source="status" label="Status" />
            <TextField source="name" label="Party name" />
            <ReferenceField label="Registration Number" source="account_number_with_ledger_number" reference="documents">
                <FunctionField render={(record) => `${record.registration_number}`} />
            </ReferenceField>
            <TextField source="vehicle_name" label="Vehicle " />

            <ReferenceField label="Vehicle" source="vehicle" reference="vehicles">
                <FunctionField render={(record) => `${record.label}`} />
            </ReferenceField>
            <TextField source="loan_amount" label="Amount" />
            <DateField source="created_at" label="Date" />
            <TextField source="area" label="Area" />
            <EditButton />
        </CustomDatagrid>
    );
};

const PartiesList = () => {
    useClearFilter("parties");
    return (
        <List actions={<PartiesFilter />} sort={{ field: "ledger_number", order: "DESC" }}>
            <CustomList />
        </List>
    );
};

export default PartiesList;
