import React, { useMemo, useState, useEffect, Fragment, useLayoutEffect } from "react";
import Grid from "@mui/material/Grid";
import { Create, SimpleForm, Edit, BooleanInput } from "react-admin";
import { useWatch, useFormContext, FormProvider } from "react-hook-form";
import { useQuery } from "react-query";
import { buildDuesTable } from "../../utils";
import { useLocation } from "react-router-dom";
import moment from "moment";
import KEYS, { getPartyAPI, getReceiptAPI } from "../../api";
import CommonTextField from "../../components/CommonTextField";
import { ReceiptsTable } from ".";
import "./ReceiptForm.styles.scss";
import SaveButtonToolbar from "../../components/SaveButtonToolbar";

const Inputs = ({ isCreate }) => {
    const accountNumber = useWatch({ name: "account_number" });
    const form = useFormContext();
    const { setValue, getValues } = useFormContext();
    const [dues, setDues] = useState(0);
    const [netDelayedAmount, setNetDelayedAmount] = useState(0);

    const paidAmount = useWatch({
        name: "receipt_amount",
    });
    const otherAmount = useWatch({
        name: "other_amount",
    });
    const defaultAmount = useWatch({
        name: "default_amount",
    });
    const date = useWatch({
        name: "date",
    });

    const closeDefaults = useWatch({
        name: "close_defaults",
    });

    // read current location state
    const { state } = useLocation();

    useLayoutEffect(() => {
        if (!date) setValue("date", moment(new Date()).format("YYYY-MM-DD"));
    }, [date]);

    const { data: partyData, refetch: getPartyData } = useQuery(
        [KEYS.GET_PARTY, accountNumber],
        () =>
            getPartyAPI({
                queryParams: {
                    order: "account_number.desc.nullslast",
                    limit: 1,
                    account_number_with_ledger_number: `eq.${accountNumber}`,
                },
            }),
        {
            enabled: false,
        }
    );

    const { data: paymentsData, refetch: getPaymentsData } = useQuery(
        [KEYS.GET_RECEIPT, accountNumber],
        () =>
            getReceiptAPI({
                queryParams: {
                    account_number: `eq.${accountNumber}`,
                    order: "id.asc.nullslast",
                    is_deleted: "eq.false",
                },
            }),
        {
            enabled: false,
        }
    );

    useEffect(() => {
        if (accountNumber) {
            getPartyData();
            getPaymentsData();
        }
    }, [accountNumber]);

    useEffect(() => {
        if (state?.account_number) {
            setValue("account_number", state.account_number);
            getPartyData();
            getPaymentsData();
        }
    }, [state]);

    const { table, defaultInterest, lastAllClosedDefaultsDate } = useMemo(() => {
        if (!paymentsData || !partyData)
            return {
                table: [],
                defaultInterest: 0,
                defaultReceived: 0,
                totalPendingAmount: 0,
                lastAllClosedDefaultsDate: null,
            };
        return buildDuesTable({
            paymentsData: [
                ...paymentsData,
                ...(paidAmount
                    ? [
                          {
                              date: moment(Date.now()).format("YYYY-MM-DD"),
                              receipt_amount: paidAmount,
                              isCurrentReceipt: true,
                              delayed_amount: 0,
                          },
                      ]
                    : []),
            ],
            accountData: partyData[0],
        });
    }, [paymentsData, partyData, paidAmount]);

    const partyDetails = useMemo(() => {
        if (partyData && partyData.length && paymentsData) {
            const { name, number_of_dues, loan_amount, created_at } = partyData[0];

            // get the first index in table where pendingAmount is not 0
            const firstPendingIndex = table.findIndex((item) => item.pendingDueAmount > 0);

            // start from the tables first pending index and go on until you find a default as 0
            // count the number of defaults in between
            const firstDefaultIndex = table.slice(firstPendingIndex).findIndex((item) => item.defaultDays === 0);

            return [
                {
                    label: "Party Name",
                    value: name,
                },

                {
                    label: "Loan Amount",
                    value: loan_amount,
                },
                {
                    label: "Loan Date",
                    value: moment(created_at).format("DD/MM/YYYY"),
                },
                {
                    label: "Last Receipt Date",
                    value: paymentsData.length ? moment(paymentsData[paymentsData.length - 1].date).format("DD/MM/YYYY") : "N/A",
                },
                {
                    label: "Last All Closed Defaults Date",
                    value: lastAllClosedDefaultsDate ? moment(lastAllClosedDefaultsDate).format("DD/MM/YYYY") : "N/A",
                },
                {
                    label: "Total Dues",
                    value: number_of_dues,
                    textColor: "text-primary",
                },
                {
                    label: "Paid Dues",
                    value: firstPendingIndex,
                    textColor: "text-primary",
                },
                {
                    label: "Pending Dues",
                    value: Math.max(firstDefaultIndex, 0),
                    textColor: "text-primary",
                },
            ];
        } else {
            return [];
        }
    }, [partyData, paymentsData, table]);

    const totalAmount = (defaultAmount || 0) + (otherAmount || 0) + (paidAmount || 0);

    return (
        <FormProvider {...form}>
            <div className="flex flex-col w-full h-[calc(100vh-10rem)]">
                <div className="flex w-full px-6 py-3 bg-background gap-12">
                    <div className="flex w-1/2 flex-col">
                        <div className="flex items-center justify-start mt-2">
                            <p className="text-base mr-2  capitalize text-secondary font-semibold">Receipts Details</p>
                        </div>
                        <div className="flex items-center mb-0.5">
                            <div className="flex items-center w-1/3">
                                <p className="text-sm  text-primary font-semibold w-full">Date</p>
                            </div>
                            <div className="flex items-center w-2/3">
                                <CommonTextField
                                    type="date"
                                    className="cursor-pointer w-full"
                                    defaultValue={moment(new Date()).add(1, "month").format("YYYY-MM-DD")}
                                    disabled={!isCreate}
                                    register={form.register("date")}
                                />
                            </div>
                        </div>
                        <div className="flex items-center mb-0.5">
                            <div className="flex items-center w-1/3">
                                <p className="text-sm  text-primary font-semibold w-full">Receipt Number</p>
                            </div>
                            <div className="flex items-center w-2/3">
                                <CommonTextField
                                    type="number"
                                    className="cursor-pointer w-full"
                                    disabled={!isCreate}
                                    register={form.register("receipt_number")}
                                />
                            </div>
                        </div>

                        <div className="flex items-center mb-0.5">
                            <div className="flex items-center w-1/3">
                                <p className="text-sm  text-primary font-semibold w-full">Acc. No.</p>
                            </div>
                            <div className="flex items-center w-2/3">
                                <CommonTextField
                                    type="text"
                                    disabled={!isCreate}
                                    className="w-full"
                                    placeholder="Account Number"
                                    register={form.register("account_number")}
                                    onKeyDown={() => {
                                        getPartyData();
                                        getPaymentsData();
                                    }}
                                />
                            </div>
                        </div>

                        {isCreate ? (
                            <Fragment>
                                <div className="flex items-center mb-0.5">
                                    <div className="flex items-center w-1/3">
                                        <p className="text-sm  text-primary font-semibold w-full">Dues</p>
                                    </div>
                                    <div className="flex items-center w-2/3">
                                        <CommonTextField
                                            defaultValue={0}
                                            type="number"
                                            className="w-full"
                                            min={0}
                                            disabled={!isCreate}
                                            register={form.register("dues", {
                                                valueAsNumber: true,
                                                onChange: (e) => {
                                                    const dues = e.target.value;

                                                    // if dues changes then change paidAmount such that paidAmount = dues * dueAmount ignore if paidAmount does not change
                                                    if (dues && partyData && partyData.length) {
                                                        const { due_amount } = partyData[0];
                                                        const newPaidAmount = dues * due_amount;
                                                        if (newPaidAmount !== paidAmount) {
                                                            setValue("receipt_amount", +newPaidAmount);
                                                        }
                                                    }
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                                <div className="flex items-center mb-0.5">
                                    <div className="flex items-center w-1/3">
                                        <p className="text-sm  text-primary font-semibold w-full">Paid Amount</p>
                                    </div>
                                    <div className="flex items-center w-2/3">
                                        <CommonTextField
                                            type="number"
                                            min={1}
                                            defaultValue={1}
                                            className="w-full"
                                            onChange={(e) => {
                                                const paidAmount = e.target.value;
                                                // if paidAmount changes then change dues such that dues = Math.ceil(paidAmount / dueAmount) ignore if dues does not change
                                                if (paidAmount && partyData && partyData.length) {
                                                    const { due_amount } = partyData[0];
                                                    const newDues = Math.ceil(paidAmount / due_amount);
                                                    if (newDues !== dues) {
                                                        setDues(+newDues);
                                                    }
                                                }
                                            }}
                                            register={form.register("receipt_amount", {
                                                valueAsNumber: true,
                                            })}
                                        />
                                    </div>
                                </div>
                                <div className="flex items-center mb-0.5">
                                    <div className="flex items-center w-1/3">
                                        <p className="text-sm  text-primary font-semibold w-full">Default Amount</p>
                                    </div>
                                    <div className="flex items-center flex-grow">
                                        <CommonTextField
                                            type="number"
                                            defaultValue={0}
                                            className="w-full"
                                            register={form.register("default_amount", {
                                                valueAsNumber: true,
                                            })}
                                        />
                                    </div>
                                    <div className="ml-4">
                                        <BooleanInput source="close_defaults" className="w-full" />
                                    </div>
                                </div>
                                <div className="flex items-center mb-0.5">
                                    <div className="flex items-center w-1/3">
                                        <p className="text-sm  text-primary font-semibold w-full">Other Amount</p>
                                    </div>
                                    <div className="flex items-center w-2/3">
                                        <CommonTextField
                                            type="number"
                                            min={0}
                                            defaultValue={0}
                                            className="w-full"
                                            register={form.register("other_amount", {
                                                valueAsNumber: true,
                                            })}
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <div className="flex items-center mt-1 mb-0.5">
                                    <div className="flex items-center w-1/3">
                                        <p className="text-sm  text-primary font-semibold w-full">Paid Amount</p>
                                    </div>
                                    <div className="flex items-center ml-2 w-2/3">
                                        <p className="text-sm  text-primary font-semibold w-full">{paidAmount || 0}</p>
                                    </div>
                                </div>
                                <div className="flex items-center mt-1 mb-0.5">
                                    <div className="flex items-center w-1/3">
                                        <p className="text-sm  text-primary font-semibold w-full">Other Amount</p>
                                    </div>
                                    <div className="flex items-center ml-2 w-2/3">
                                        <p className="text-sm  text-primary font-semibold w-full">{otherAmount || 0}</p>
                                    </div>
                                </div>
                                <div className="flex items-center mt-1 mb-0.5">
                                    <div className="flex items-center w-1/3">
                                        <p className="text-sm  text-primary font-semibold w-full">Total Delayed Amount</p>
                                    </div>
                                    <div className="flex items-center ml-2 w-2/3">
                                        <p className="text-sm  text-primary font-semibold w-full">{netDelayedAmount || 0}</p>
                                    </div>
                                </div>
                            </Fragment>
                        )}
                    </div>
                    <div className="flex w-1/2 flex-col">
                        {partyDetails.map(({ label, value, textColor }, index) => (
                            <div className="flex items-center mt-1 mb-0.5" key={index}>
                                <div className="flex items-center w-1/3">
                                    <p className={`text-sm  ${textColor || "text-black"} font-semibold w-full whitespace-nowrap`}>{label}</p>
                                </div>
                                <div className="flex items-center pl-16 w-2/3">
                                    <p className="text-sm  text-primary font-semibold w-full">{value}</p>
                                </div>
                            </div>
                        ))}

                        <div className="flex flex-grow flex-col justify-end">
                            <div className="flex items-center mt-1 mb-0.5">
                                <div className="flex items-center w-1/3">
                                    <p className="text-sm  text-black font-semibold w-full">Default Rs.</p>
                                </div>
                                <div className="flex items-center pl-16 w-2/3">
                                    <p className="text-sm  text-primary font-semibold w-full">{defaultInterest || 0}</p>
                                </div>
                            </div>
                            <div className="flex items-center mt-1 mb-0.5">
                                <div className="flex items-center w-1/3">
                                    <p className="text-sm  text-black font-semibold w-full">Total Amount</p>
                                </div>
                                <div className="flex items-center pl-16 w-2/3">
                                    <p className="text-sm  text-primary font-semibold w-full">{totalAmount}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex w-full flex-col mt-4 px-6 bg-background gap-12">
                    <div className="grid grid-cols-2 "></div>
                </div>
                <div className="w-full flex-grow overflow-auto" id="receipt-form__container">
                    {isCreate && table?.length ? <ReceiptsTable partyData={partyData} paymentsData={paymentsData} table={table} /> : ""}
                </div>
            </div>
        </FormProvider>
    );
};

const ReceiptForm = ({ isCreate, ...props }) => {
    const Wrapper = isCreate ? Create : Edit;

    const additionalProps = isCreate
        ? {}
        : {
              toolbar: <SaveButtonToolbar />,
          };

    const transform = (data) => {
        delete data.dues;
        if (data.id) {
            delete data.id;
        }
        delete data.dues;
        if (!data.receipt_number) {
            delete data.receipt_number;
        }
        data.paid_amount = (data.receipt_amount || 0) + (data.default_amount || 0);
        return { ...data };
    };

    return (
        <Wrapper {...props} className="receipt-create !bg-transparent mt-2" actions={false} redirect="show" transform={transform}>
            <SimpleForm
                warnWhenUnsavedChanges
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        e.preventDefault();

                        // @ts-ignore
                        document.activeElement?.blur();
                    }
                }}
                className="!bg-transparent"
                {...additionalProps}
            >
                <Inputs isCreate={isCreate} />
            </SimpleForm>
        </Wrapper>
    );
};

export default ReceiptForm;
