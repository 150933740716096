import { List, TextField, ReferenceField, FunctionField, EditButton } from "react-admin";
import { useClearFilter } from "../../hooks";
import CustomReceiptFilter from "./CustomReceiptFilter";
import CustomDatagrid from "../../components/CustomDatagrid";

const CustomList = () => {
    return (
        <CustomDatagrid bulkActionButtons={false} rowClick="show" resource="custom_receipts">
            <TextField source="id" label="Custom Receipt Number" />
            <TextField source="date" label="date" />
            <TextField source="account_number" label="account number" />
            <ReferenceField label="Party Name" source="account_number" reference="parties" link="show">
                <FunctionField render={(record) => record && `${record.name}`} />
            </ReferenceField>
            <TextField source="type" label="details" />
            <TextField source="amount" label="Amount" />
            <ReferenceField source="account_number" reference="documents">
                <FunctionField
                    className="form-content"
                    render={(record) => {
                        return record.registration_number;
                    }}
                />
            </ReferenceField>
            <TextField source="vehicle_number" />
            <EditButton />
        </CustomDatagrid>
    );
};

const CustomReceiptList = ({ props }) => {
    useClearFilter("custom_receipts");

    return (
        <List
            {...props}
            actions={<CustomReceiptFilter />}
            perPage={50}
            sort={{
                field: "date",
                order: "DESC",
            }}
        >
            <CustomList />
        </List>
    );
};

export default CustomReceiptList;
