import { useClearFilter } from "../../hooks";
import PartyLedgerFilter from "./PartyLedgerFilter";
import { Datagrid, List, TextField, EditButton, downloadCSV } from "react-admin";
import jsonExport from "jsonexport/dist";

const exporter = (records) => {
    // sort records in ascending order of ledger number if ledger_number is same then sort in ascending order of account_number
    records.sort((a, b) => {
        if (a.ledger_number === b.ledger_number) {
            return a.account_number - b.account_number;
        }
        return a.ledger_number - b.ledger_number;
    });

    const recordsForExport = records.map((record) => {
        // have only account_number_with_ledger_number, closing_amount rounded to two decimal
        return {
            account_number: record.account_number_with_ledger_number,
            closing_amount: record.closing_amount.toFixed(2),
            name: record.name,
        };
    });

    jsonExport(
        recordsForExport,
        {
            headers: ["account_number", "name", "closing_amount"], // order fields in the export
        },
        (err, csv) => {
            downloadCSV(csv, "parties"); // download as 'posts.csv` file
        }
    );
};

const PartyLedgerList = () => {
    useClearFilter("party_ledger_with_closing_amount");

    return (
        <List
            actions={<PartyLedgerFilter />}
            resource="party_ledger_with_closing_amount"
            sort={{ field: "account_number", order: "ASC" }}
            exporter={exporter}
            filterDefaultValues={{ "ledger_number@gte": 32 }}
        >
            <Datagrid bulkActionButtons={false}>
                <TextField label="Account Number" source="account_number_with_ledger_number" />
                <TextField source="name" label="Party name" />
                {/* <TextField source="opening_amount" label="Opening Amount" /> */}
                <TextField source="closing_amount" label="Closing Amount" />
                {/* <EditButton /> */}
            </Datagrid>
        </List>
    );
};

export default PartyLedgerList;
