import { getGenericPostRequest, getGenericGetRequest, buildURLs, buildKeys, getGenericPutRequest, getGenericPatchRequest } from "./utils";

const BASE_URLS = {
    // Get requests
    GET_CONSTANT: "constants",
    GET_PARTY: "parties",
    GET_HAND_LOANS: "hand_loans",
    GET_RECEIPT: "receipts",
    GET_AREA: "areas",
    // Post requests
    POST_AREA: "areas",
    // Update requests
    UPDATE_RECEIPT: "receipts",
};

let prefix = "";

prefix = `${localStorage.getItem("backend-url") || process.env.REACT_APP_POSTGREST_URL}/`;

const URLS = buildURLs({
    urls: BASE_URLS,
    prefix,
});

// Export all generic functions
// GET requests
export const getConstantAPI = getGenericGetRequest(URLS.GET_CONSTANT);
export const getPartyAPI = getGenericGetRequest(URLS.GET_PARTY);
export const getHandLoansAPI = getGenericGetRequest(URLS.GET_HAND_LOANS);
export const getReceiptAPI = getGenericGetRequest(URLS.GET_RECEIPT);
export const getAreaAPI = getGenericGetRequest(URLS.GET_AREA);

// POST requests
export const postAreaAPI = getGenericPostRequest(URLS.POST_AREA);
// PUT requests

// Patch requests
export const updateReceiptAPI = getGenericPatchRequest(URLS.UPDATE_RECEIPT);

// Export query keys
const KEYS = buildKeys(BASE_URLS);

export default KEYS;
