import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import CommonTextField from "../../components/CommonTextField";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Autocomplete from "@mui/material/Autocomplete";
import { useQuery } from "react-query";
import KEYS, { getHandLoansAPI } from "../../api";
import TextField from "@mui/material/TextField";

const HandLoan = ({ setTotalHandLoan, setTotalHandLoans, isCreate }) => {
    const { data: handLoanData } = useQuery([KEYS.GET_HAND_LOANS], () => getHandLoansAPI());

    const [rows, setRows] = useState([
        {
            name: "",
            amount: 0,
        },
        {
            name: "",
            amount: 0,
        },
    ]);

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        const newRows = [...rows];

        if (name === "amount" && value < 0) return;
        newRows[index][name] = value || "";
        if (index === rows.length - 1 && name === "amount") {
            if (newRows[index].amount > 0) {
                setRows([...newRows, { name: "", amount: 0 }]);
            }
        } else {
            setRows(newRows);
        }
    };

    const handLoanNames = handLoanData && handLoanData.map((handLoan) => handLoan.label);

    useEffect(() => {
        // filter out rows with 0 amount and convert amount to number
        const filteredRows = rows.filter((row) => row.amount > 0).map((row) => ({ ...row, amount: +row.amount }));
        const totalHandLoan = filteredRows.reduce((acc, row) => acc + +row.amount, 0);
        setTotalHandLoan(totalHandLoan);
        setTotalHandLoans(JSON.stringify(filteredRows));
    }, [setTotalHandLoan, rows, setTotalHandLoans]);

    return (
        <TableContainer className="mt-2 !rounded-none overflow-x-visible !max-h-52 no-scrollbar overflow-y-scroll" component={Paper} elevation={1}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className="text-sm max-h-8 !px-2 !py-0 !text-primary !font-semibold ">HL Name</TableCell>
                        <TableCell className="text-sm max-h-8 !px-2 !py-0 !text-primary !font-semibold " align="right">
                            Amount
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => {
                        return (
                            <TableRow key={index}>
                                <TableCell component="td" scope="row" className="max-h-8 !px-0 !py-0">
                                    <Autocomplete
                                        options={handLoanNames || []}
                                        disabled={!isCreate}
                                        className="auto-complete w-full"
                                        onChange={(e, value) =>
                                            handleChange(
                                                {
                                                    target: {
                                                        name: "name",
                                                        value,
                                                    },
                                                },
                                                index
                                            )
                                        }
                                        inputValue={row.name}
                                        onInputChange={(e, value) =>
                                            handleChange(
                                                {
                                                    target: {
                                                        name: "name",
                                                        value,
                                                    },
                                                },
                                                index
                                            )
                                        }
                                        value={row.name}
                                        defaultValue=""
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    style: {
                                                        ...(params.inputProps.style || {}),
                                                        height: "30px",
                                                        boxSizing: "border-box",
                                                    },
                                                }}
                                                variant="filled"
                                                name="name"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </TableCell>
                                <TableCell align="right" className="max-h-8 !px-0 !py-0">
                                    <CommonTextField
                                        fullWidth
                                        type="number"
                                        className="w-full"
                                        name="amount"
                                        min={0}
                                        onChange={(e) => handleChange(e, index)}
                                        value={row.amount}
                                        disabled={!isCreate}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default HandLoan;
