import { List, TextField } from "react-admin";
import { useClearFilter } from "../../hooks";
import VehiclesListFilter from "./VehiclesListFilter";
import CustomDatagrid from "../../components/CustomDatagrid";

const CustomList = () => {
    return (
        <CustomDatagrid bulkActionButtons={false} rowClick="edit" resource="vehicles">
            <TextField source="label" />
        </CustomDatagrid>
    );
};

const VehiclesList = (props) => {
    useClearFilter("vehicles");

    return (
        <List {...props} actions={<VehiclesListFilter />}>
            <CustomList />
        </List>
    );
};

export default VehiclesList;
