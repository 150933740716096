import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useCreatePath } from "react-admin";

const CustomAppBar = (props) => {
    const NAV_OPTIONS = [
        {
            name: "Parties",
            link: "parties",
        },
        {
            name: "Party Ledger",
            link: "party_ledger_with_closing_amount",
        },
        {
            name: "Vehicles",
            link: "vehicles",
        },
        {
            name: "Constants",
            link: "constants",
        },
        {
            name: "Hand loans",
            link: "hand_loans",
        },
        {
            name: "Brokers",
            link: "brokers",
        },
        {
            name: "Areas",
            link: "areas",
        },
        {
            name: "Dealers",
            link: "dealers",
        },
        {
            name: "Documents",
            link: "documents",
        },
        {
            name: "Receipts",
            link: "receipts",
        },
        {
            name: "Custom receipts",
            link: "custom_receipts",
        },
    ];

    const location = useLocation();
    const createPath = useCreatePath();

    return (
        <div className="w-full flex items-center justify-between px-6 py-3 top-0 z-50 bg-[#475739] !text-white sticky">
            <p className="text-base font-semibold ">{props.title}</p>
            <div className="flex items-center">
                {NAV_OPTIONS.map((option) => (
                    <Link
                        className={`text-white font-medium hover:underline text-sm mx-2 ${
                            location.pathname.split("/")[1] === option.link ? "underline" : ""
                        } `}
                        to={createPath({ resource: option.link, type: "list" })}
                    >
                        {option.name}
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default CustomAppBar;
