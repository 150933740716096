import { TableCell, TableHead, TableRow } from "@mui/material";
import React, { Children } from "react";
import { Datagrid, DatagridBody, RecordContextProvider, useCreate, useCreatePath } from "react-admin";
import { useNavigate } from "react-router";

const DatagridHeader = ({ children }) => (
    <TableHead>
        <TableRow>
            {Children.map(children, (child) => {
                return (
                    <TableCell className="border border-solid !border-gray-700  bg-background whitespace-nowrap capitalize" key={child.props.source}>
                        {child.props.label}
                    </TableCell>
                );
            })}
        </TableRow>
    </TableHead>
);

const MyDatagridRow = ({ record, id, children, resource, rowClick }) => {
    const navigate = useNavigate();

    const createPath = useCreatePath();

    return (
        <RecordContextProvider value={record}>
            <TableRow
                className="border-l border-r border-solid !border-gray-700 shadow-none hover:bg-[#e9e9e9] cursor-pointer"
                onClick={() =>
                    navigate(
                        createPath({
                            type: rowClick,
                            id: record.id,
                            resource,
                        })
                    )
                }
            >
                {Children.map(children, (field) => (
                    <TableCell key={`${id}-${field.props.source}`} className="shadow-none">
                        {field}
                    </TableCell>
                ))}
            </TableRow>
        </RecordContextProvider>
    );
};

const MyDatagridBody = (props) => (
    <DatagridBody className="bg-background !shadow-none" {...props} row={<MyDatagridRow resource={props.resource} rowClick={props.rowClick} />} />
);

const MyDatagrid = (props) => (
    <Datagrid {...props} header={<DatagridHeader />} body={<MyDatagridBody resource={props.resource} rowClick={props.rowClick} />} />
);

const CustomDatagrid = ({ children, resource, ...props }) => {
    return (
        <MyDatagrid {...props} resource={resource}>
            {children}
        </MyDatagrid>
    );
};

export default CustomDatagrid;
