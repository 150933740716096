const CUSTOM_GREY = {
    /**
     * Preview: ![grey 50](https://mui.com/static/colors-preview/grey-50-24x24.png)
     */
    50: "#f0f0f0",
    /**
     * Preview: ![grey 100](https://mui.com/static/colors-preview/grey-100-24x24.png)
     */
    100: "#f0f0f0",
    /**
     * Preview: ![grey 200](https://mui.com/static/colors-preview/grey-200-24x24.png)
     */
    200: "#f0f0f0",
    /**
     * Preview: ![grey 300](https://mui.com/static/colors-preview/grey-300-24x24.png)
     */
    300: "#f0f0f0",
    /**
     * Preview: ![grey 400](https://mui.com/static/colors-preview/grey-400-24x24.png)
     */
    400: "#f0f0f0",
    /**
     * Preview: ![grey 500](https://mui.com/static/colors-preview/grey-500-24x24.png)
     */
    500: "#f0f0f0",
    /**
     * Preview: ![grey 600](https://mui.com/static/colors-preview/grey-600-24x24.png)
     */
    600: "#f0f0f0",
    /**
     * Preview: ![grey 700](https://mui.com/static/colors-preview/grey-700-24x24.png)
     */
    700: "#f0f0f0",
    /**
     * Preview: ![grey 800](https://mui.com/static/colors-preview/grey-800-24x24.png)
     */
    800: "#f0f0f0",
    /**
     * Preview: ![grey 900](https://mui.com/static/colors-preview/grey-900-24x24.png)
     */
    900: "#f0f0f0",
    /**
     * Preview: ![grey A100](https://mui.com/static/colors-preview/grey-A100-24x24.png)
     */
    A100: "#f0f0f0",
    /**
     * Preview: ![grey A200](https://mui.com/static/colors-preview/grey-A200-24x24.png)
     */
    A200: "#f0f0f0",
    /**
     * Preview: ![grey A400](https://mui.com/static/colors-preview/grey-A400-24x24.png)
     */
    A400: "#f0f0f0",
    /**
     * Preview: ![grey A700](https://mui.com/static/colors-preview/grey-A700-24x24.png)
     */
    A700: "#f0f0f0",
};

export default CUSTOM_GREY;
