import axios from "axios"

// Get auth token
export const getAuthTokenHeader = () => {
    const authToken = localStorage.getItem("token")

    if (!authToken) return {}

    return process.env.REACT_APP_DISABLE_JWT
        ? {}
        : {
            Authorization: `Bearer ${authToken}`,
        }
}

// Append all the routes with the URL_PREFIX
export const buildURLs = ({ urls, prefix }) => {
    const newUrls = {}

    for (const key of Object.keys(urls)) {
        newUrls[key] = prefix + urls[key]
    }

    return newUrls
}

// Build keys for react query
export const buildKeys = (urls, prefix = "") => {
    const keys = {}

    for (const key of Object.keys(urls)) {
        keys[key] = prefix + key
    }

    return keys
}

export const getGenericGetRequest =
    (url, shouldUseBasURL = true, withCredentials = true) =>
        async (props = {}) => {
            const { pathParams, queryParams } = props

            const headers = {
                ...getAuthTokenHeader(),
            }

            let urlWithPathParams = url

            // Add path params to url
            if (pathParams) {
                for (const key of Object.keys(pathParams)) {
                    urlWithPathParams = urlWithPathParams.replace(`:${key}`, pathParams[key])
                }
            }

            const options = shouldUseBasURL
                ? {
                    baseURL: "/",
                }
                : {}

            // Add query params to options
            if (queryParams) {
                options.params = queryParams
            }
            options.withCredentials = withCredentials

            options.headers = headers

            const response = await axios.get(urlWithPathParams, options)

            return response.data
        }

// Function to return the generic post request
export const getGenericPostRequest = (url) => async (postData) => {
    const { pathParams, ...data } = postData || {}
    const headers = {}

    let urlWithPathParams = url

    // Add path params to url
    if (pathParams) {
        for (const key of Object.keys(pathParams)) {
            urlWithPathParams = urlWithPathParams.replace(`:${key}`, pathParams[key])
        }
    }

    const options = {
        baseURL: "/",
        headers,
        withCredentials: true,
    }

    const response = await axios.post(url, data || {}, options)
    return response.data
}

// Function to return the generic put request
export const getGenericPutRequest = (url) => async (data) => {
    const headers = {
        "Content-Type": "application/json",
        ...getAuthTokenHeader(),
    }

    const response = await axios.put(url, data || {}, {
        headers,
        baseURL: "/",
        data: JSON.stringify(data),
        withCredentials: true,
    })
    return response.data
}

// Function to return the generic patch request
export const getGenericPatchRequest =
    (url) =>
        async ({ data, queryParams }) => {
            const headers = {
                "Content-Type": "application/json",
                ...getAuthTokenHeader(),
            }

            const options = {
                baseURL: "/",
                headers,
                withCredentials: true,
            }

            // Add query params to options
            if (queryParams) {
                options.params = queryParams
            }

            const response = await axios.patch(url, data || {}, options)

            return response.data
        }
