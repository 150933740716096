import { List, TextField } from "react-admin";
import { useClearFilter } from "../../hooks";
import BrokersListFilter from "./BrokersListFilter";
import CustomDatagrid from "../../components/CustomDatagrid";

const CustomList = () => {
    return (
        <CustomDatagrid resource="brokers">
            <TextField source="name" />
            <TextField source="address" />
            <TextField source="phone" />
        </CustomDatagrid>
    );
};
const BrokersList = () => {
    useClearFilter("brokers");

    return (
        <List actions={<BrokersListFilter />}>
            <CustomList />
        </List>
    );
};

export default BrokersList;
