import { List, TextField } from "react-admin";
import { useClearFilter } from "../../hooks";
import HandLoanListFilter from "./HandLoanListFilter";
import CustomDatagrid from "../../components/CustomDatagrid";

const CustomList = () => {
    return (
        <CustomDatagrid bulkActionButtons={false} rowClick="edit" resource="hand_loans">
            <TextField source="label" />
        </CustomDatagrid>
    );
};

const HandLoanList = (props) => {
    useClearFilter("hand_loans");

    return (
        <List {...props} actions={<HandLoanListFilter />}>
            <CustomList />
        </List>
    );
};

export default HandLoanList;
