import { Fragment, useRef } from "react";
import ReactToPrint from "react-to-print";
import { Show, SimpleShowLayout } from "react-admin";
import PrintCustomReceipt from "./PrintCustomReceipt";
import Grid from "@mui/material/Grid";

const Layout = () => {
    const componentRef = useRef();
    return (
        <SimpleShowLayout style={{ padding: "2rem" }}>
            <Grid container justifyContent="center" alignItems="center">
                <ReactToPrint trigger={() => <button>Print this out!</button>} content={() => componentRef.current} />
                <PrintCustomReceipt ref={componentRef} />
            </Grid>
        </SimpleShowLayout>
    );
};

const ShowCustomReceipt = () => {
    return (
        <Show actions={Fragment} className="my-2" title="Custom Reciept Details">
            <Layout />
        </Show>
    );
};

export default ShowCustomReceipt;
