import React from "react";
import { CreateButton } from "react-admin";

const HandLoanListFilter = () => {
    return (
        <div className="w-full my-4 bg-background p-4">
            <div className="flex flex-row justify-end w-full">
                <div className="flex w-1/5 flex-col center">
                    <CreateButton
                        disableRipple
                        className="!text-sm !flex !items-center !capitalize !justify-center !w-full !mb-1 !h-8 !bg-white px-4  py-1 !text-black !rounded-sm !border !border-gray-200"
                    />
                </div>
            </div>
        </div>
    );
};

export default HandLoanListFilter;
