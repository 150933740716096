import * as React from "react";
import { useState } from "react";
import { useLogin, useNotify, Notification, Button } from "react-admin";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

const MyLoginPage = () => {
    const [password, setPassword] = useState("");
    const login = useLogin();
    const notify = useNotify();

    const handleSubmit = (e) => {
        e.preventDefault();
        login({ password }).catch(() => notify("Invalid email or password"));
    };

    return (
        <Paper>
            <Grid container direction="row" style={{ padding: "10rem", width: "100%" }}>
                <Grid item xs={12}>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            fullWidth
                            label="Password"
                            name="password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Button fullWidth variant="contained" color="primary" type="submit" style={{ marginTop: "1rem" }}>
                            Login
                        </Button>
                    </form>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default MyLoginPage;
