import React from "react";
import { List, TextField, ReferenceField, FunctionField } from "react-admin";
import { useClearFilter } from "../../hooks";
import DocumentsListFilter from "./DocumentsListFilter";
import CustomDatagrid from "../../components/CustomDatagrid";
import CreateReceiptButton from "../../components/CreateReceiptButton";

const CustomList = () => {
    return (
        <CustomDatagrid bulkActionButtons={false} rowClick="edit" resource="documents">
            <CreateReceiptButton />
            <TextField fullWidth source="account_number" label="A/c No" />
            <ReferenceField label="Name" source="account_number" reference="parties" link="show">
                <FunctionField render={(record) => record && `${record.name}`} />
            </ReferenceField>
            <TextField source="registration_number" label="Reg. No" />
            <TextField source="rc_owner" label="Rc Owner" />
            <TextField source="date_of_registration" label="Date of Reg." />
            <TextField source="insurance" label="Insurance" />
        </CustomDatagrid>
    );
};

const DocumentsList = ({ props }) => {
    useClearFilter("documents");

    return (
        <List {...props} actions={<DocumentsListFilter />} sort={{ field: "account_number", order: "DESC" }}>
            <CustomList />
        </List>
    );
};

export default DocumentsList;
