import moment from "moment"
import { getDueDates } from "."

// A loan is made up of multiple receipts, each of which is a payment
// of a portion of the loan
// we have details on when the payment was made, how much was paid,
// we need to generate receipts for each installments including the delayed interest with delayed interest rates
//
// The function should take in the loan details and return an array of receipts
// Each receipt should have the following details:
// 1. Receipt due amount
// 2. Receipt delayed interest
const getSplitReceipts = ({ numberOfDues, currentReceiptID, dueAmount, defaultInterest, dueStart, payments }) => {
    const splitReceipts = []
    const dueDates = getDueDates({ numberOfDues, dueStart })

    // initialize the receipts with the due amount and default interest
    for (let i = 0; i < numberOfDues; i++) {
        const receipt = {
            dueAmount,
            dueDate: dueDates[i],
            delayedInterest: 0,
            lateDays: 0,
            remainingDueAmount: dueAmount,
            remainingDelayedAmount: 0,
            paidInPreviousReceipts: true,
            dueIndex: i,
            isCurrentReceipt: false,
        }
        splitReceipts.push(receipt)
    }

    let currentReceiptIndex = 0
    let previousReceiptIsCurrentModifiedReceipt = false



    // from payments data, update the receipts
    payments.forEach((payment, index) => {
        const paymentDate = moment(payment.date, "YYYY-MM-DD")
        const paymentAmount = payment.receipt_amount
        let runningPaymentAmount = paymentAmount

        // calculate delayed interest for each receipt, payment amount need not be equal to due amount
        // if payment amount is greater than due amount, then the remaining amount is carried forward to the next receipt
        // if payment amount is less than due amount, then the remaining amount is carried forward to the next receipt
        // if payment amount is equal to due amount, then the remaining amount is carried forward to the next receipt
        while (runningPaymentAmount > 0 && currentReceiptIndex < numberOfDues) {
            const currentReceiptDueDate = moment(splitReceipts[currentReceiptIndex].dueDate, "YYYY-MM-DD")

            if (payment.id == currentReceiptID && splitReceipts[currentReceiptIndex].remainingDueAmount != 0 && runningPaymentAmount >= 0) {
                splitReceipts[currentReceiptIndex].isCurrentReceipt = true
            }

            if (!previousReceiptIsCurrentModifiedReceipt && payment.isCurrentReceipt) {
                // we're moving into current receipt so we have to reset the due amount
                splitReceipts[currentReceiptIndex].dueAmount = splitReceipts[currentReceiptIndex].remainingDueAmount
            }

            previousReceiptIsCurrentModifiedReceipt = payment.isCurrentReceipt

            if (index === payments.length - 1) {
                splitReceipts[currentReceiptIndex].paidInPreviousReceipts = false
            }

            // calculate late days
            splitReceipts[currentReceiptIndex].lateDays = Math.max(Math.ceil(paymentDate.diff(currentReceiptDueDate, "days")), 0)

            // calculate delayed interest
            // this is current delayed interest + previous delyed interest
            // splitReceipts[currentReceiptIndex].delayedInterest = Math.ceil(
            //     (splitReceipts[currentReceiptIndex].lateDays * splitReceipts[currentReceiptIndex].remainingDueAmount * defaultInterest) / (100 * 365)
            // )
            // splitReceipts[currentReceiptIndex].remainingDelayedAmount = splitReceipts[currentReceiptIndex].delayedInterest

            // calculate remaining due amount
            if (runningPaymentAmount >= splitReceipts[currentReceiptIndex].remainingDueAmount) {
                runningPaymentAmount -= splitReceipts[currentReceiptIndex].remainingDueAmount
                splitReceipts[currentReceiptIndex].remainingDueAmount = 0
            } else {
                splitReceipts[currentReceiptIndex].remainingDueAmount -= runningPaymentAmount
                // set remaining delayed interest to 0
                // add it to remaining due amount
                // this is needed to compound the interest
                // splitReceipts[currentReceiptIndex].remainingDueAmount += splitReceipts[currentReceiptIndex].remainingDelayedAmount
                // splitReceipts[currentReceiptIndex].remainingDelayedAmount = 0
                runningPaymentAmount = 0
            }

            // if we have exhausted the payment amount, then we can move to the next payment
            if (runningPaymentAmount == 0) {
                break
            }

            // if we have not exhausted the payment amount, then check if we can pay the delayed interest
            // if we can pay the delayed interest, then we can move to the next payment
            // if we cannot pay the delayed interest, then we need to carry forward the delayed interest to the next payment
            // if (runningPaymentAmount >= splitReceipts[currentReceiptIndex].remainingDelayedAmount) {
            //     // runningPaymentAmount -= splitReceipts[currentReceiptIndex].remainingDelayedAmount
            //     splitReceipts[currentReceiptIndex].remainingDelayedAmount = 0
            // } else {
            // splitReceipts[currentReceiptIndex].remainingDelayedAmount -= runningPaymentAmount
            // set remaining delayed interest to 0
            // add it to remaining due amount
            // this is needed to compound the interest
            // splitReceipts[currentReceiptIndex].remainingDueAmount += splitReceipts[currentReceiptIndex].remainingDelayedAmount
            // splitReceipts[currentReceiptIndex].remainingDelayedAmount = 0
            // runningPaymentAmount = 0
            // }

            // if both the due amount and delayed interest are paid, then we can move to the next receipt
            if (splitReceipts[currentReceiptIndex].remainingDueAmount == 0) {
                currentReceiptIndex++
            }

            // if we have exhausted the payment amount, then we can move to the next payment
            if (runningPaymentAmount == 0) {
                break
            }
        }
    })

    for (let i = currentReceiptIndex; i < numberOfDues; i++) {
        splitReceipts[i].paidInPreviousReceipts = false
    }

    for (let i = currentReceiptIndex + 1; i < numberOfDues; i++) {
        splitReceipts[i].notPaidInPreviousReceiptsAndPending = true
    }



    return splitReceipts
}

export default getSplitReceipts
