import React, { Fragment } from "react";

const CommonTextField = (props) => {
    return (
        <Fragment>
            {props.multiline ? (
                <textarea
                    type={props.type ?? "text"}
                    className={`border focus:bg-primary mx-1 resize-none  focus:text-white max-h-24 no-scrollbar overflow-y-auto rounded-sm text-black border-gray-200 text-sm outline-none bg-white px-4 py-2 ${
                        props.disabled ? "cursor-not-allowed bg-gray-300" : ""
                    } ${props.className} `}
                    defaultValue={props.defaultValue}
                    disabled={props.disabled ?? false}
                    placeholder={props.placeholder ?? ""}
                    {...props.register}
                    rows={props.rows ?? 2}
                />
            ) : (
                <input
                    type={props.type ?? "text"}
                    className={`border focus:bg-primary mx-1 focus:text-white h-8 rounded-sm text-black border-gray-200 text-sm outline-none bg-white px-4 py-2 ${
                        props.disabled ? "cursor-not-allowed bg-gray-300" : ""
                    } ${props.className} `}
                    defaultValue={props.defaultValue}
                    disabled={props.disabled ?? false}
                    {...(props.min ? { min: props.min } : null)}
                    {...(props.onBlur ? { onblur: props.onBlur } : null)}
                    placeholder={props.placeholder ?? ""}
                    onKeyDown={(e) => {
                        if (props.type === "number" && e.key === "e") {
                            e.preventDefault();
                        } else if (e.key === "Enter" && props.onKeyDown) {
                            props.onKeyDown();
                        }
                    }}
                    {...(props.register
                        ? {
                              ...props.register,

                              onChange: (e) => {
                                  props.register.onChange(e);
                              },
                          }
                        : {
                              onChange: props.onChange,
                              value: props.value,
                              name: props.name,
                          })}
                />
            )}
        </Fragment>
    );
};

export default CommonTextField;
