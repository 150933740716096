import moment from "moment";

const getDueDates = ({ numberOfDues, dueStart }) => {
    const dueDates = [];
    for (let i = 0; i < numberOfDues; i++) {
        dueDates.push(moment(dueStart, "YYYY-MM-DDTHH:mm:ss").add(i, "months"));
    }
    return dueDates;
};

export default getDueDates;
