import React, { useEffect, useMemo } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "./ReceiptPrint.styles.scss";
import { TextField, FunctionField, ReferenceField, useShowContext } from "react-admin";
import moment from "moment";
import { getSplitReceipts, priceInWords } from "../../utils";
import KEYS, { getReceiptAPI, getPartyAPI } from "../../api";
import { useQuery } from "react-query";
import { isCursorAtEnd } from "@testing-library/user-event/dist/utils";

const ReceiptPrint = React.forwardRef((props, ref) => {
    const { record } = useShowContext();

    const accountNumber = record && record.account_number;
    const recordId = record && record.id;

    const { data: paymentsData, refetch: getPaymentsData } = useQuery(
        [KEYS.GET_RECEIPT, accountNumber, recordId],
        () =>
            getReceiptAPI({
                queryParams: {
                    account_number: `eq.${accountNumber}`,
                    order: "created_at.asc.nullslast",
                    is_deleted: "eq.false",
                },
            }),
        {
            enabled: false,
        }
    );

    const { data: partyData, refetch: getPartyData } = useQuery(
        [KEYS.GET_PARTY, accountNumber],
        () =>
            getPartyAPI({
                queryParams: {
                    order: "account_number.desc.nullslast",
                    limit: 1,
                    account_number_with_ledger_number: `eq.${accountNumber}`,
                },
            }),
        {
            enabled: false,
        }
    );

    useEffect(() => {
        if (accountNumber && recordId) {
            getPaymentsData();
            getPartyData();
        }
    }, [getPaymentsData, getPartyData, accountNumber, recordId]);

    const { dueDates, dueIndexRange } = useMemo(() => {
        if (partyData && partyData.length && paymentsData && paymentsData.length) {
            if (paymentsData && partyData) {
                const party = partyData[0];
                const { due_start: dueStart, number_of_dues: numberOfDues, due_amount: dueAmount, default_interest: defaultInterest } = party;

                const receipts = getSplitReceipts({
                    numberOfDues,
                    dueAmount,
                    defaultInterest,
                    dueStart,
                    payments: paymentsData,
                    currentReceiptID: recordId,
                }).filter(({ isCurrentReceipt }) => isCurrentReceipt);

                const dueIndexRange = receipts
                    .map(({ dueIndex }) => dueIndex + 1)
                    .map((dueIndex) => {
                        const j = dueIndex % 10,
                            k = dueIndex % 100;
                        if (j === 1 && k !== 11) {
                            return dueIndex + "st";
                        }
                        if (j === 2 && k !== 12) {
                            return dueIndex + "nd";
                        }
                        if (j === 3 && k !== 13) {
                            return dueIndex + "rd";
                        }
                        return dueIndex + "th";
                    });

                const dueDates = receipts.map(({ dueDate }) => moment(dueDate).format("DD/MM/YYYY"));

                return {
                    dueIndexRange: `${dueIndexRange[0]}${
                        dueIndexRange[0] === dueIndexRange[dueIndexRange.length - 1] ? "" : ` to ${dueIndexRange[dueIndexRange.length - 1]}`
                    }`,
                    dueDates: dueDates.join(", "),
                };
            }
        }
        return {};
    }, [paymentsData, partyData]);

    return (
        <div ref={ref} className="print-form-container">
            <Grid container className="print-form-content">
                <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                    <Typography className="heading">
                        A/c No : <TextField className="content" source="account_number" />
                    </Typography>
                    <Grid item xs="auto" container direction="column">
                        <Typography className="heading">
                            L. No : <span className="content">9/2004-05</span>{" "}
                        </Typography>
                        <Typography className="heading phone">
                            <div className="flex flex-row">
                                <div className="mr-1">Ph No :</div>
                                <div>
                                    <span className="content">98429 84920</span> <br />
                                    <span className="content">9360758439</span>
                                </div>
                            </div>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} container justifyContent="center" alignItems="center">
                    <Typography variant="h3" className="main-heading">
                        P.G. MONEY LENDERS
                    </Typography>
                </Grid>
                <Grid item xs={12} container justifyContent="center" alignItems="center">
                    <Typography variant="h3" className="address">
                        6,LYOLA COMPLEX, A.A.ROAD,GNANAOLIVUPURAM, MADURAI -625016
                    </Typography>
                </Grid>
                <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                    <Grid item xs={3} container justifyContent="flex-start" alignItem="center">
                        <Typography className="heading">
                            Rcpt No: <TextField className="content underlined" source="receipt_number" />
                        </Typography>
                    </Grid>
                    <Grid item xs={6} container justifyContent="center" alignItem="center">
                        <Typography className="main-heading-2 underlined">RECEIPT</Typography>
                    </Grid>
                    <Grid item xs={3} container justifyContent="flex-end" alignItem="center">
                        <Typography className="heading">
                            Date : <FunctionField className="content underlined" render={(record) => moment(record.date).format("DD / MM / YYYY")} />
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} container alignItems="center">
                    <Grid item xs="auto" container alignItems="center">
                        <Typography className="content-heading">Received with thanks from Sri.</Typography>
                    </Grid>
                    <Grid item xs={true} alignItems="center" className="print-form__value">
                        <ReferenceField source="account_number" reference="parties" link={null}>
                            <FunctionField className="form-content" render={(record) => record.name} />
                        </ReferenceField>
                    </Grid>
                </Grid>
                <Grid item xs={12} container alignItems="center">
                    <Grid item xs="auto" container alignItems="center">
                        <Typography className="content-heading">The Sum Of Rupees</Typography>
                    </Grid>
                    <Grid item xs={true} alignItems="center" className="print-form__value">
                        <FunctionField className="form-content" render={(record) => priceInWords(Math.ceil(record.paid_amount)) + " Only"} />
                    </Grid>
                </Grid>
                <Grid item xs={12} container alignItems="center">
                    <Grid item xs="auto" container alignItems="center">
                        <Typography className="content-heading">By Cash / Draft / Cheque (subject to realization) in</Typography>
                    </Grid>
                    <Grid item xs={true} alignItems="center" className="print-form__value">
                        <Typography className="form-content hidden">{"Hidden"}</Typography>
                    </Grid>
                    <Grid item xs="auto" container alignItems="center">
                        <Typography className="content-heading">part / full</Typography>
                    </Grid>
                    <Grid item xs={true} alignItems="center" className="print-form__value">
                        <Typography className="form-content hidden">{"Hidden"}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} container alignItems="center">
                    <Grid item xs="auto" container alignItems="center">
                        <Typography className="content-heading">payment of the</Typography>
                    </Grid>
                    <Grid item xs={true} alignItems="center" className="print-form__value">
                        <Typography className="form-content">{dueIndexRange}</Typography>
                    </Grid>
                    <Grid item xs="auto" container alignItems="center">
                        <Typography className="content-heading">installment of H.P. Agreement of Vehicle No. </Typography>
                    </Grid>
                    <Grid item xs={true} alignItems="center" className="print-form__value">
                        <ReferenceField source="account_number" reference="documents">
                            <FunctionField className="form-content !text-black" render={(record) => record.registration_number} />
                        </ReferenceField>
                    </Grid>
                </Grid>
                <Grid item xs={12} container alignItems="center">
                    <Grid item xs="auto" container alignItems="center">
                        <Typography className="content-heading">Due Date</Typography>
                    </Grid>
                    <Grid item xs={true} alignItems="center" className="print-form__value">
                        <Typography className="form-content">{dueDates}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                    <FunctionField render={(record) => <Typography className="total-amt">RS. {record.paid_amount} /-</Typography>} />
                    <Typography className="party-name_footer">for P.G. MONEY LENDERS</Typography>
                </Grid>
                <Grid item xs={12} container justifyContent="flex-end" alignItems="center">
                    {/* <Typography className="party-name_footer">Sign</Typography> */}
                </Grid>
                <Grid item xs={12} container justifyContent="flex-end" alignItems="center">
                    <Typography className="party-name_footer">partner / Manager</Typography>
                </Grid>
            </Grid>
        </div>
    );
});

export default ReceiptPrint;
