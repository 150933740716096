import React from "react";
import { List, TextField } from "react-admin";
import { useClearFilter } from "../../hooks";
import CustomDatagrid from "../../components/CustomDatagrid";

const CustomList = () => {
    return (
        <CustomDatagrid bulkActionButtons={false} rowClick="show" resource="areas">
            <TextField label="Area" source="name" />
        </CustomDatagrid>
    );
};

const PartiesList = () => {
    useClearFilter("areas");
    return (
        <List>
            <CustomList />
        </List>
    );
};

export default PartiesList;
