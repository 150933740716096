import React, { useEffect, useState, Fragment, useLayoutEffect } from "react";
import { useQuery } from "react-query";
import CommonTextField from "../../components/CommonTextField";
import CircularProgress from "@mui/material/CircularProgress";
import { Create, Edit, SimpleForm, AutocompleteInput, ReferenceInput, useCreate } from "react-admin";
import { useFormContext, useWatch, FormProvider } from "react-hook-form";
import SaveButtonToolbar from "../../components/SaveButtonToolbar";
import moment from "moment";
import KEYS, { getConstantAPI, getPartyAPI } from "../../api";
import { HandLoan } from ".";

const Inputs = ({ isCreate }) => {
    const { register } = useFormContext();

    return (
        <div className="flex w-full  px-6 py-3 bg-background gap-12">
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <p className="text-sm mr-2 text-primary font-semibold w-full whitespace-nowrap">Ledger Number</p>
                    <CommonTextField type="text" placeholder="Ledget Number" register={register("value")} />
                </div>
            </div>
        </div>
    );
};

const PartyForm = ({ isCreate, ...props }) => {
    const Wrapper = isCreate ? Create : Edit;

    const additionalProps = isCreate
        ? {}
        : {
              toolbar: <SaveButtonToolbar />,
          };

    const transform = (data) => {
        // delete id if it is present
        if (data.id) {
            delete data.id;
        }

        return {
            ...data,
        };
    };

    return (
        <Wrapper {...props} className="my-2" actions={false} redirect="list" transform={transform}>
            <SimpleForm
                warnWhenUnsavedChanges
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        e.preventDefault();

                        // @ts-ignore
                        document.activeElement.blur();
                    }
                }}
                {...additionalProps}
            >
                <Inputs isCreate={isCreate} />
            </SimpleForm>
        </Wrapper>
    );
};

export default PartyForm;
