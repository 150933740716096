import React, { Fragment } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Create, SimpleForm, required, ReferenceInput, AutocompleteInput } from "react-admin";
import TextInput from "../../components/CustomTextInput";

const CreateParty = (props) => {
    return (
        <Create className="my-2" actions={Fragment} {...props}>
            <SimpleForm
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        e.preventDefault();
                        document.activeElement.blur();
                    }
                }}
            >
                <Grid container direction="row" justifyContent="center" columnSpacing={6}>
                    <Grid item xs={6} container direction="column">
                        <Typography style={{ fontWeight: "600" }} variant="h5">
                            Area details
                        </Typography>
                        <TextInput label="Area" source="name" validate={[required()]} />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    );
};

export default CreateParty;
