import React from "react";
import { useRedirect, useRecordContext } from "react-admin";

const CreateReceiptButton = () => {
    const redirect = useRedirect();
    const record = useRecordContext();
    const handleClick = (e) => {
        e.stopPropagation();
        redirect(
            "create",
            "receipts",
            {},
            {},
            {
                account_number: record.account_number_with_ledger_number || record.account_number,
            }
        );
    };

    return (
        <button
            onClick={handleClick}
            className="text-sm w-full h-full bg-primary hover:bg-primary/50 mb-1 px-4 text-white rounded-sm border border-gray-200"
        >
            Create Receipt
        </button>
    );
};

export default CreateReceiptButton;
