import { TextInput } from "react-admin";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";

const SearchInput = (props) => (
    <TextInput
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    <SearchIcon color="disabled" />
                </InputAdornment>
            ),
        }}
        {...props}
    />
);

export default SearchInput;
