import React, { Fragment } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";

const columns = [
    { id: "index", label: "No." },
    { id: "dueDate", label: "Due Date" },
    { id: "dueAmount", label: "Due Amt" },
    { id: "receiptNumber", label: "Rcpt No." },
    { id: "receiptDate", label: "Rcpt Date" },
    { id: "defaultReceived", label: "Due Rcvd" },
    { id: "pendingDueAmount", label: "Pending Due Amt" },
    { id: "defaultDays", label: "Days" },
    {
        id: "OD",
        label: "OD",
    },
];

const ShowPartyTable = ({ tableData }) => {
    const totalDueAmount = tableData?.reduce((acc, curr) => acc + curr.dueAmount, 0) || 0;
    const totalDays = tableData?.reduce((acc, curr) => acc + (curr.defaultDays || 0), 0) || 0;
    const totalDueReceived = tableData?.reduce((acc, curr) => acc + curr.dueAmount - curr.pendingDueAmount, 0) || 0;
    const totalPendingAmount = tableData?.reduce((acc, curr) => acc + curr.pendingDueAmount, 0) || 0;
    const totalOD = tableData?.reduce((acc, curr) => acc + curr.OD, 0) || 0;

    if (!tableData) return <Fragment />;

    return (
        <Paper sx={{ width: "100%" }} elevation={0} className="border border-solid border-gray-700 !rounded-none">
            <TableContainer
                sx={{
                    overflowX: "hidden",
                    maxHeight: "42vh",
                }}
            >
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => (
                                <TableCell
                                    key={index}
                                    align={column.align}
                                    className="border border-solid border-t-0 !border-b-0 border-l-0 border-gray-700 !px-2 !py-1 whitespace-nowrap !bg-background last:border-r-0"
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableHead role="checkbox" tabIndex={-1}>
                        <TableRow>
                            {" "}
                            <TableCell
                                style={{
                                    whiteSpace: "nowrap",
                                    borderRightStyle: "solid",
                                    borderRightColor: "#e0e0e0",
                                    fontWeight: "bold",
                                }}
                                className="!bg-background  border-b-0 border-l-0 border border-solid !border-gray-700 !px-2 !py-1"
                            >
                                Total
                            </TableCell>
                            <TableCell className="!bg-background border-b-0 border-t border-l-0 border  border-solid !border-gray-700"></TableCell>
                            <TableCell
                                style={{
                                    whiteSpace: "nowrap",
                                    borderRightStyle: "solid",
                                    borderRightColor: "#e0e0e0",
                                    fontWeight: "bold",
                                }}
                                className="!bg-background border border-solid border-l-0 !border-gray-700 !px-2 !py-1"
                            >
                                {totalDueAmount}
                            </TableCell>
                            <TableCell className="!bg-background border-t border-b-0 border-l-0 border-solid !border-gray-700 !px-2 !py-1"></TableCell>
                            <TableCell className="!bg-background border-t border-b-0 border-l-0 border-solid !border-gray-700 !px-2 !py-1"></TableCell>
                            <TableCell
                                style={{
                                    whiteSpace: "nowrap",
                                    borderRightStyle: "solid",
                                    borderRightColor: "#e0e0e0",
                                    fontWeight: "bold",
                                }}
                                className="!bg-background border border-solid border-b-0 border-l-0 !border-gray-700 !px-2 !py-1"
                            >
                                {totalDueReceived}
                            </TableCell>
                            <TableCell
                                style={{
                                    whiteSpace: "nowrap",
                                    borderRightStyle: "solid",
                                    borderRightColor: "#e0e0e0",
                                    fontWeight: "bold",
                                }}
                                className="!bg-background border border-solid border-b-0 border-l-0 !border-gray-700 !px-2 !py-1"
                            >
                                {totalPendingAmount}
                            </TableCell>
                            <TableCell
                                style={{
                                    whiteSpace: "nowrap",
                                    borderRightStyle: "solid",
                                    borderRightColor: "#e0e0e0",
                                    fontWeight: "bold",
                                }}
                                className="!bg-background border border-solid border-b-0 border-l-0 !border-gray-700 !px-2 !py-1"
                            >
                                {totalDays < 0 ? 0 : totalDays}
                            </TableCell>
                            <TableCell
                                style={{
                                    whiteSpace: "nowrap",
                                    borderRightStyle: "solid",
                                    borderRightColor: "#e0e0e0",
                                    fontWeight: "bold",
                                }}
                                className="!bg-background border border-solid border-b-0 border-l-0 !border-gray-700 !px-2 !py-1 border-r-0"
                            >
                                {totalOD < 0 ? 0 : totalOD}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData.map((row, index) => {
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.dueDate}
                                    style={
                                        row.pendingDueAmount <= 0
                                            ? {
                                                  backgroundColor: "pink",
                                              }
                                            : row.defaultDays > 0
                                            ? {
                                                  backgroundColor: "skyblue",
                                              }
                                            : {}
                                    }
                                >
                                    {columns.map((column, columnIndex) => {
                                        let value;
                                        switch (column.id) {
                                            case "dueDate":
                                                value = moment(row.dueDate).format("DD/MM/YYYY");
                                                break;
                                            case "dueAmount":
                                                value = row.dueAmount;
                                                break;
                                            case "pendingDueAmount":
                                                value = row.pendingDueAmount;
                                                break;
                                            case "defaultReceived":
                                                value = row.dueAmount - row.pendingDueAmount;
                                                break;
                                            case "receiptNumber":
                                                value = row.receiptNumber;
                                                break;
                                            case "receiptDate":
                                                value = moment(row.receiptDate).format("DD/MM/YYYY");
                                                break;
                                            case "defaultDays":
                                                value = row.defaultDays;
                                                if (value < 0) {
                                                    value = 0;
                                                }
                                                break;
                                            case "OD":
                                                value = row.OD;
                                                break;

                                            case "index":
                                                value = index + 1;
                                                break;
                                            default:
                                                value = 0;
                                        }

                                        return (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                className="!px-2 !py-1 border border-solid !border-gray-300 border-l-0 border-t-0"
                                                style={
                                                    index === tableData.length - 1 && columnIndex === columns.length - 1
                                                        ? {}
                                                        : {
                                                              whiteSpace: "nowrap",
                                                              borderRightStyle: "solid",
                                                              borderRightColor: "#e0e0e0",
                                                          }
                                                }
                                            >
                                                {column.format && typeof value === "number" ? column.format(value) : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default ShowPartyTable;
