import React, { useMemo } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";

import { useWatch } from "react-hook-form";
import { getSplitReceipts } from "../../utils";

const columns = [
    { id: "due_no", label: "Due No" },
    { id: "due_date", label: "Due Date" },
    { id: "pending_amount", label: "Pending Amount" },
    { id: "received_amount", label: "Received Amount" },
    {
        id: "default_late_days",
        label: "Late Days",
    },
];

const ReceiptsTable = ({ partyData, paymentsData, table }) => {
    const paidAmount = useWatch({
        name: "paid_amount",
    });
    const delayedAmount = useWatch({
        name: "delayed_amount",
    });
    const date = useWatch({
        name: "date",
    });

    const receipts = useMemo(() => {
        if (paymentsData && partyData && partyData.length) {
            const party = partyData[0];
            const { due_start: dueStart, number_of_dues: numberOfDues, due_amount: dueAmount, default_interest: defaultInterest } = party;

            const receipts = getSplitReceipts({
                numberOfDues,
                dueAmount,
                defaultInterest,
                dueStart,
                payments: date
                    ? [
                          ...paymentsData,
                          {
                              date,
                              paid_amount: paidAmount,
                              isCurrentReceipt: true,
                              delayed_amount: delayedAmount,
                          },
                      ]
                    : [...paymentsData],
            }).filter(({ paidInPreviousReceipts }) => !paidInPreviousReceipts);

            return receipts;
        }

        return [];
    }, [paymentsData, partyData, date, paidAmount]);

    const currentTable = useMemo(() => {
        return table.filter(({ pendingDueAmount, isCurrentReceipt }) => pendingDueAmount > 0 || isCurrentReceipt);
    }, [paymentsData, partyData, date, paidAmount, table]);

    return (
        <Paper className="w-full h-full overflow-auto !rounded-none">
            <TableContainer className="h-full overflow-auto">
                <Table stickyHeader aria-label="sticky table" className="border !border-gray-700 overflow-auto">
                    <TableHead className="sticky z-50">
                        <TableRow>
                            <TableCell
                                align="center"
                                className="!bg-background border border-t-0 border-l-0 !border-b-0 !px-2 !py-0 !max-h-8 !border-gray-700"
                                colSpan={4}
                            >
                                Due details
                            </TableCell>
                            <TableCell
                                align="center"
                                className="!bg-background !px-2 !py-0 !max-h-8 border border-t-0 !border-b-0 border-l-0 border-r-0 !border-gray-700"
                            >
                                OD Details
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            {columns.map((column, index) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    className="!px-2 !py-1 text-sm !bg-background !font-semibold border border-l-0 last:border-r-0 !border-gray-700"
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentTable.map((row, index) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.dueDate}>
                                    {columns.map((column, columnIndex) => {
                                        let value;
                                        switch (column.id) {
                                            case "due_no":
                                                value = row.dueNo;
                                                break;
                                            case "due_date":
                                                value = moment(row.dueDate).format("DD/MM/YYYY");
                                                break;
                                            case "pending_amount":
                                                value = row.pendingDueAmount;
                                                break;
                                            case "received_amount":
                                                value = row.dueAmount - row.pendingDueAmount;
                                                break;
                                            case "default_late_days":
                                                value = row.defaultDays;
                                                break;
                                            case "default_current_amount":
                                                value = row.defaultAmount;
                                                break;
                                            case "default_received_amount":
                                                value = row.defaultReceived;
                                                break;

                                            default:
                                                value = 0;
                                        }

                                        const isPendingAmountZero = row.pendingDueAmount === 0;

                                        return (
                                            <TableCell
                                                className={`!px-2 !py-1 cursor-pointer !border !border-gray-400 !border-t-0 !border-l-0 last:!border-r-0 ${
                                                    isPendingAmountZero ? "!text-secondary !font-semibold" : ""
                                                }`}
                                                key={column.id}
                                                align={column.align}
                                            >
                                                {column.format && typeof value === "number" ? column.format(value) : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default ReceiptsTable;
