import { Fragment, useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { Show, SimpleShowLayout, useShowContext, useNotify, useRedirect } from "react-admin";
import ReceiptPrint from "./ReceiptPrint";
import Grid from "@mui/material/Grid";
import { useMutation } from "react-query";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { updateReceiptAPI } from "../../api";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "3px",
    boxShadow: 24,
    p: 2,
};

const Layout = () => {
    const componentRef = useRef();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const notify = useNotify();

    const { record } = useShowContext();
    const redirect = useRedirect();

    const { mutate: deleteReceipt, data } = useMutation(
        ({ id }) => {
            return updateReceiptAPI({
                queryParams: {
                    id: "eq." + id,
                },
                data: {
                    is_deleted: true,
                },
            });
        },
        {
            onSuccess: () => {
                notify("Receipt Deleted", {
                    type: "success",
                });
                redirect("list", "receipts");
            },
        }
    );

    return (
        <SimpleShowLayout style={{ padding: "2rem" }}>
            <Grid container justifyContent="center" alignItems="center">
                <ReactToPrint
                    trigger={() => (
                        <button
                            className="bg-primary text-white
                    px-4 py-2 rounded-sm
                    "
                        >
                            Print this out!
                        </button>
                    )}
                    content={() => componentRef.current}
                />
                <ReceiptPrint ref={componentRef} />
                <Button variant="contained" className="delete-btn" onClick={handleOpen}>
                    Delete
                </Button>
            </Grid>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Grid container>
                        <Grid item xs={12} container justifyContent="center">
                            <Typography id="modal-modal-title" variant="h6" align="center" component="h2">
                                Are you sure you want to delete this receipt?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} container justifyContent="space-around">
                            <Button
                                variant="contained"
                                className="modal-btn"
                                onClick={() =>
                                    deleteReceipt({
                                        id: record?.id,
                                    })
                                }
                            >
                                yes
                            </Button>
                            <Button variant="contained" className="modal-btn" onClick={handleClose}>
                                No
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </SimpleShowLayout>
    );
};

const ShowReceipt = () => {
    return (
        <Show actions={Fragment} className="my-2" title="Reciept Details">
            <Layout />
        </Show>
    );
};

export default ShowReceipt;
