import React from "react";
import { useListContext, ExportButton } from "react-admin";
import { useForm, FormProvider } from "react-hook-form";

const PartyLedgerFilter = () => {
    const { filterValues, setFilters } = useListContext();

    const form = useForm({
        defaultValues: filterValues,
    });

    const resetFilter = () => {
        form.reset();
        setFilters({}, []);
    };

    console.log({
        filterValues,
    });

    const onSubmit = (values) => {
        setFilters(
            {
                ...filterValues,
                "ledger_number@gte": Math.max(values.from_ledger_number ?? 0, 32),
                "ledger_number@lte": values.to_ledger_number ?? Number.POSITIVE_INFINITY,
            },
            []
        );
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="w-full my-4 bg-background p-4">
                <div className="flex justify-between">
                    <div className="flex flex-row w-full">
                        <div className="flex w-2/5 flex-col mx-4">
                            <div className="flex  justify-between mb-1 items-center">
                                <p className="text-sm  capitalize text-primary font-semibold">From Ledger</p>
                                <input
                                    type="number"
                                    className="cursor-pointer border w-4/6 h-8 rounded-sm border-gray-200 text-sm outline-none bg-white px-4 py-2"
                                    {...form.register("from_ledger_number")}
                                />
                            </div>
                            <div className="flex justify-between mb-1 items-center">
                                <p className="text-sm  capitalize text-primary font-semibold">To Ledger</p>
                                <input
                                    type="number"
                                    className="cursor-pointer border w-4/6 h-8 rounded-sm border-gray-200 text-sm outline-none bg-white px-4 py-2"
                                    {...form.register("to_ledger_number")}
                                />
                            </div>
                        </div>
                        <div className="flex w-1/5 flex-col center">
                            <button
                                type="submit"
                                className="text-sm w-full mb-1 h-8 bg-white px-4  py-1 text-black rounded-sm border border-gray-200"
                            >
                                Search Now
                            </button>
                            <button
                                className="text-sm bg-white w-full text-black h-8 px-4 mb-1  py-1 rounded-sm border border-gray-200"
                                onClick={resetFilter}
                            >
                                New Search
                            </button>
                        </div>
                    </div>
                    <div className="flex">
                        <ExportButton />
                    </div>
                </div>
            </form>
        </FormProvider>
    );
};

export default PartyLedgerFilter;
