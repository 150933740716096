import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const columns = [
    { id: "name", label: "Particulars" },
    { id: "amount", label: "Dr Amt" },
];

const HandloanTable = ({ handLoanData }) => {
    let data = JSON.parse(handLoanData || "[]");
    return (
        <Paper sx={{ width: "100%" }} className="w-full border-gray-700 border border-solid !rounded-none">
            <TableContainer
                sx={{
                    overflowX: "hidden",
                    maxHeight: "50vh",
                }}
            >
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    className="border border-solid !border-gray-700 !px-2 !py-1 whitespace-nowrap !bg-background border-r-0 first:border-l-0 border-t-0"
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.map((row, index) => {
                            return (
                                <TableRow hover role="checkbox" key={index}>
                                    {columns.map((column, columnIndex) => {
                                        let value;
                                        switch (column.id) {
                                            case "name":
                                                value = row.name;
                                                break;
                                            case "amount":
                                                value = row.amount;
                                                break;
                                            default:
                                                value = 0;
                                        }

                                        return (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={
                                                    index === handLoanData.length - 1 && columnIndex === columns.length - 1
                                                        ? {}
                                                        : {
                                                              whiteSpace: "nowrap",
                                                          }
                                                }
                                                className={`!bg-background border border-solid !border-gray-300 !px-2 !py-1 border-t-0 border-r-0 first:border-l-0 ${
                                                    index === data.length - 1 ? "!border-b-0" : ""
                                                }`}
                                            >
                                                {column.format && typeof value === "number" ? column.format(value) : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default HandloanTable;
