import React from "react";
import { DateField, List, TextField, EditButton, ReferenceField, FunctionField } from "react-admin";
import { useClearFilter } from "../../hooks";
import CustomDatagrid from "../../components/CustomDatagrid";
import CreateReceiptButton from "../../components/CreateReceiptButton";

const CustomList = () => {
    return (
        <CustomDatagrid bulkActionButtons={false} rowClick="show" resource="constants">
            <TextField source="label" />
            <TextField source="value" />
            <EditButton />
        </CustomDatagrid>
    );
};

const ConstantsList = () => {
    useClearFilter("constants");
    return (
        <List
            filter={{
                label: "ledger_number",
            }}
        >
            <CustomList />
        </List>
    );
};

export default ConstantsList;
