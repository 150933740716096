import React from "react";
import { useListContext, CreateButton } from "react-admin";
import { useForm, FormProvider } from "react-hook-form";

const ReceiptsFilter = () => {
    const { filterValues, setFilters } = useListContext();

    const form = useForm({
        defaultValues: filterValues,
    });

    const resetFilter = () => {
        form.reset();
        setFilters({}, []);
    };

    const onSubmit = (values) => {
        const filters = {
            ...filterValues,
            account_number: values.account_number_with_ledger_number,
            receipt_number: values.receipt_number,
        };

        delete filters["date@gte"];
        delete filters["date@lte"];
        delete filters["date@eq"];

        if (values.fromDate && values.toDate) {
            filters["date@gte"] = values.fromDate;
            filters["date@lte"] = values.toDate;
        } else if (values.fromDate) {
            filters["date@eq"] = values.fromDate;
        } else if (values.toDate) {
            filters["date@eq"] = values.toDate;
        }

        setFilters(filters);
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="w-full my-4 bg-background p-4">
                <div className="flex flex-row w-full">
                    <div className="flex w-2/5 flex-col mr-4">
                        <div className="flex justify-between mb-1 items-center">
                            <p className="text-sm  capitalize text-primary font-semibold">Acc. No.</p>
                            <input
                                type="text"
                                className="border w-4/6 h-8 rounded-sm border-gray-200 text-sm outline-none bg-white px-4 py-2"
                                placeholder="A/C. No."
                                {...form.register("account_number_with_ledger_number")}
                            />
                        </div>
                        <div className="flex justify-between mb-1 items-center">
                            <p className="text-sm  capitalize text-primary font-semibold">Receipt. No.</p>
                            <input
                                type="text"
                                className="border w-4/6 h-8 rounded-sm border-gray-200 text-sm outline-none bg-white px-4 py-2"
                                placeholder="Receipt. No."
                                {...form.register("receipt_number")}
                            />
                        </div>
                    </div>
                    <div className="flex w-2/5 flex-col mx-4">
                        <div className="flex  justify-between mb-1 items-center">
                            <p className="text-sm  capitalize text-primary font-semibold basis-1/4">From Date</p>
                            <div className="flex justiy-start flex-grow">
                                <input
                                    type="date"
                                    className="cursor-pointer flex-grow border w-4/6 h-8 rounded-sm border-gray-200 text-sm outline-none bg-white px-4 py-2"
                                    {...form.register("fromDate")}
                                />
                                <button
                                    onClick={() => {
                                        form.setValue("fromDate", "");
                                    }}
                                    className="ml-3 bg-red-500 text-white rounded-full p-1 px-3"
                                >
                                    x
                                </button>
                            </div>
                        </div>
                        <div className="flex justify-between mb-1 items-center">
                            <p className="text-sm capitalize text-primary font-semibold basis-1/4">To Date</p>
                            <div className="flex justiy-start flex-grow">
                                <input
                                    type="date"
                                    className="flex-grow cursor-pointer border w-4/6 h-8 rounded-sm border-gray-200 text-sm outline-none bg-white px-4 py-2"
                                    {...form.register("toDate")}
                                />
                                <button
                                    onClick={() => {
                                        form.setValue("toDate", "");
                                    }}
                                    className="ml-3 bg-red-500 text-white rounded-full p-1 px-3"
                                >
                                    x
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="flex w-1/5 flex-col center">
                        <button type="submit" className="text-sm w-full mb-1 h-8 bg-white px-4  py-1 text-black rounded-sm border border-gray-200">
                            Search Now
                        </button>
                        <button
                            className="text-sm bg-white w-full text-black h-8 px-4 mb-1  py-1 rounded-sm border border-gray-200"
                            onClick={resetFilter}
                        >
                            New Search
                        </button>
                        <CreateButton
                            disableRipple
                            className="!text-sm !flex !items-center !capitalize !justify-center !w-full !mb-1 !h-8 !bg-white px-4  py-1 !text-black !rounded-sm !border !border-gray-200"
                        />
                    </div>
                </div>
            </form>
        </FormProvider>
    );
};

export default ReceiptsFilter;
