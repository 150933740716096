import { Toolbar, SaveButton } from "react-admin";

const SaveButtonToolbar = (props) => (
    <Toolbar {...props}>
        <div className="w-full flex justify-end">
            <SaveButton />
        </div>
    </Toolbar>
);

export default SaveButtonToolbar;
