import React, { Fragment, useEffect } from "react";
import { Admin, fetchUtils, Resource } from "react-admin";
import { BrowserRouter } from "react-router-dom";
import CustomReceiptForm from "./pages/CustomReceiptForm";
import postgrestRestProvider from "@promitheus/ra-data-postgrest";
import PartiesList from "./pages/PartiesList";
import ShowCustomReceipt from "./pages/ShowCustomReceipt";
import BrokersList from "./pages/BrokersList";
import CustomReceiptList from "./pages/CustomReceiptList";
import CreateBroker from "./pages/CreateBroker";
import CreateDealer from "./pages/CreateDealer";
import DealersList from "./pages/DealersList";
import CreateDocument from "./pages/CreateDocument";
import VehiclesForm from "./pages/VehiclesForm";
import VehiclesList from "./pages/VehiclesList";
import HandLoanForm from "./pages/HandLoanForm";
import HandLoanList from "./pages/HandLoanList";
import ShowDocument from "./pages/ShowDocument";
import CustomAppBar from "./components/CustomAppBar";
import ReceiptForm from "./pages/ReceiptForm";
import PartyForm from "./pages/PartyForm";
import DocumentsList from "./pages/DocumentsList";
import ReceiptsList from "./pages/ReceiptsList";
import ShowReceipt from "./pages/ShowReceipt";
import ShowParty from "./pages/ShowParty";
import Login from "./pages/Login";
import { defaultTheme, Layout } from "react-admin";
import { CUSTOM_GREY } from "./globalConstants";
import CustomMenu from "./pages/CustomMenu";
import ConstantsList from "./pages/ConstantsList/ConstantsList";
import ConstantsEdit from "./pages/ConstantsEdit/ConstantsEdit";
import AreasList from "./pages/AreasList";
import CreateArea from "./pages/CreateArea";
import PartyLedgerList from "./pages/PartyLedgerList";

const theme = {
    ...defaultTheme,
    palette: {
        secondary: CUSTOM_GREY,
        background: {
            default: "#c0cdb4",
        },
    },
};

const authProvider = {
    login: function (_a) {
        localStorage.setItem("token", _a.password);
        return Promise.resolve();
    },
    logout: function (params) {
        localStorage.clear();
        return Promise.resolve();
    },
    checkAuth: function (params) {
        return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
    },
    checkError: function (error) {
        var status = error.status;
        if (status === 401 || status === 403) {
            localStorage.clear();
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: function (params) {
        return localStorage.getItem("token") ? Promise.resolve("admin") : Promise.reject();
    },
};

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: "application/json" });
    }

    const token = localStorage.getItem("token");

    if (!process.env.REACT_APP_DISABLE_JWT) options.headers.set("Authorization", `Bearer ${token}`);

    return fetchUtils.fetchJson(url, options);
};

const CustomSidebar = (props) => <div></div>;

const MyLayout = (props) => {
    return <Layout className="" {...props} appBar={CustomAppBar} sidebar={CustomSidebar} menu={CustomMenu} />;
};

const dataProvider = postgrestRestProvider(
    localStorage.getItem("backend-url") || process.env.REACT_APP_POSTGREST_URL,
    httpClient,
    "eq",
    new Map([
        ["parties", ["account_number_with_ledger_number"]],
        ["vehicles", ["id"]],
        ["hand_loans", ["label"]],
        ["documents", ["account_number"]],
        ["areas", ["name"]],
        ["constants", ["label"]],
    ])
);
const App = () => {
    useEffect(() => {
        const handleKeyDown = (event) => {
            switch (event.keyCode) {
                case 112:
                    event.preventDefault();
                    window.location = "/parties";
                    break;
                case 113:
                    window.location = "/documents";
                    break;
                case 115:
                    window.location = "/parties/create";
                    break;

                case 116:
                    window.location = "/receipts/create";
                    break;

                case 119:
                    window.location = "/parties";
                    break;
                case 27:
                    if (window.location.hash !== "#/" && !window.location.hash.includes("create") && !window.location.hash.includes("edit")) {
                        window.history.back();
                    }
                    break;
                // if press esc go back
                default:
                    break;
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    return (
        <BrowserRouter>
            <div className="bg-black">
                <Admin
                    loginPage={Login}
                    dataProvider={dataProvider}
                    authProvider={process.env.REACT_APP_DISABLE_JWT ? null : authProvider}
                    requireAuth={!process.env.REACT_APP_DISABLE_JWT}
                    theme={theme}
                    layout={MyLayout}
                >
                    <Resource name="constants" list={ConstantsList} edit={<ConstantsEdit isCreate={false} />} />
                    <Resource
                        name="parties"
                        list={PartiesList}
                        edit={() => <PartyForm isCreate={false} />}
                        create={() => <PartyForm isCreate={true} />}
                        show={ShowParty}
                        icon={Fragment}
                    />
                    <Resource name="party_ledger_with_closing_amount" label="Party Ledger" list={PartyLedgerList} icon={Fragment} />

                    <Resource name="vehicles" list={VehiclesList} create={VehiclesForm} icon={Fragment} />
                    <Resource name="hand_loans" list={HandLoanList} create={HandLoanForm} icon={Fragment} />

                    <Resource name="brokers" list={BrokersList} create={CreateBroker} icon={Fragment} />
                    <Resource name="dealers" list={DealersList} create={CreateDealer} icon={Fragment} />
                    <Resource name="areas" list={AreasList} create={CreateArea} icon={Fragment} />
                    <Resource
                        name="documents"
                        list={DocumentsList}
                        create={() => <CreateDocument isCreate={true} />}
                        show={ShowDocument}
                        edit={() => <CreateDocument isCreate={false} />}
                        icon={Fragment}
                    />
                    <Resource
                        name="receipts"
                        list={ReceiptsList}
                        create={() => <ReceiptForm isCreate={true} />}
                        edit={() => <ReceiptForm isCreate={false} />}
                        show={() => <ShowReceipt />}
                        icon={Fragment}
                    />
                    <Resource
                        name="custom_receipts"
                        label="Temporary Receipts"
                        list={CustomReceiptList}
                        create={() => <CustomReceiptForm isCreate={true} />}
                        edit={() => <CustomReceiptForm isCreate={false} />}
                        show={() => <ShowCustomReceipt />}
                        icon={Fragment}
                    />
                </Admin>
            </div>
        </BrowserRouter>
    );
};

export default App;
