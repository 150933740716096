import React, { Fragment, useState } from "react";
import { Create, SimpleForm, Edit, BooleanInput, ReferenceInput, AutocompleteInput } from "react-admin";
import { useFormContext, useWatch, FormProvider } from "react-hook-form";
import moment from "moment";
import CommonTextField from "../../components/CommonTextField";
import { useQuery } from "react-query";
import KEYS, { getPartyAPI } from "../../api";
import { useEffect } from "react";
import "./CreateDocument.styles.scss";
import SaveButtonToolbar from "../../components/SaveButtonToolbar";

const Layout = () => {
    const accountInputValue = useWatch({
        name: "account_number",
    });

    const form = useFormContext();

    const { setValue, getValues, ...rest } = useFormContext();

    const { data: partyData, refetch } = useQuery(
        [KEYS.GET_PARTY, accountInputValue],
        () => {
            if (accountInputValue) {
                const [ledgerNumber, accountNumber] = accountInputValue.split("/");

                return getPartyAPI({
                    queryParams: {
                        order: "account_number.desc.nullslast",
                        limit: 1,
                        ledger_number: `eq.${ledgerNumber}`,
                        account_number: `eq.${accountNumber}`,
                    },
                });
            }

            return null;
        },
        {
            enabled: false,
        }
    );

    useEffect(() => {
        refetch();
    }, [accountInputValue]);

    useEffect(() => {
        if (partyData?.length) {
            setValue("name", partyData[0].name);
        } else {
            setValue("name", "");
        }
    }, [partyData]);

    return (
        <FormProvider {...form}>
            <div className="flex w-full  px-6 py-3 bg-background gap-12">
                <div className="flex w-1/2 flex-col">
                    <div className="flex items-center justify-start mt-2">
                        <p className="text-base mr-2  capitalize text-secondary font-semibold">Document Details</p>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Acc. No.</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField type="text" className="w-full" placeholder="Account Number" register={form.register("account_number")} />
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Name</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField type="text" className="w-full" placeholder="" register={form.register("name")} />
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <BooleanInput helperText={false} fullWidth source="rc_book" />
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">RC Owner</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField type="text" className="w-full" placeholder="" register={form.register("rc_owner")} />
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">RTO Office</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <ReferenceInput source="rto_office" reference="rto_offices" allowEmpty>
                                <AutocompleteInput className={`auto-complete w-full`} helperText={false} label={false} optionText="name" />
                            </ReferenceInput>
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Reg. No.</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField
                                type="text"
                                className="w-full"
                                placeholder="Registration Number"
                                register={form.register("registration_number")}
                            />
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Chassis No.</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField type="text" className="w-full" placeholder="" register={form.register("chassis_number")} />
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Engine No.</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField type="text" className="w-full" placeholder="" register={form.register("engine_number")} />
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Key No.</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField type="text" className="w-full" placeholder="" register={form.register("key_number")} />
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Date of Reg.</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField
                                type="date"
                                className="cursor-pointer w-full"
                                defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                                placeholder=""
                                register={form.register("date_of_registration")}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex w-1/2 flex-col">
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Tax Expiry</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField
                                type="date"
                                className="cursor-pointer w-full"
                                defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                                placeholder=""
                                register={form.register("tax_expiry")}
                            />
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Permit Expiry</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField
                                type="date"
                                className="cursor-pointer w-full"
                                defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                                placeholder=""
                                register={form.register("permit_expiry")}
                            />
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">FC Expiry</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField
                                type="date"
                                className="cursor-pointer w-full"
                                defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                                placeholder=""
                                register={form.register("fc_expiry")}
                            />
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <BooleanInput helperText={false} fullWidth source="insurance" />
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Insurance Company</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField type="text" className="w-full" placeholder="" register={form.register("insurance_company")} />
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Insurance Policy</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField type="text" className="w-full" placeholder="" register={form.register("insurance_policy")} />
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Insurance Expiry Date</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField
                                type="date"
                                className="cursor-pointer w-full"
                                defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                                placeholder=""
                                register={form.register("insurance_expiry_date")}
                            />
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <BooleanInput helperText={false} fullWidth source="invoice_copy" />
                    </div>
                    <div className="flex items-center mb-0.5">
                        <BooleanInput helperText={false} fullWidth source="address_proof" />
                    </div>
                    <div className="flex items-center mb-0.5">
                        <BooleanInput helperText={false} fullWidth source="photo" />
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Remarks</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField type="text" className="w-full" placeholder="" register={form.register("remarks")} />
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Photo Path</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField type="text" className="w-full" placeholder="" register={form.register("photo_path")} />
                        </div>
                    </div>
                </div>
            </div>
        </FormProvider>
    );
};

const CreateDocument = ({ isCreate, ...props }) => {
    const Wrapper = isCreate ? Create : Edit;

    const additionalProps = isCreate
        ? {}
        : {
              toolbar: <SaveButtonToolbar />,
          };

    const transform = (data) => {
        if (data.id) {
            delete data.id;
        }
        return { ...data };
    };

    return (
        <Wrapper {...props} className="my-2" redirect="show" actions={Fragment} mutationMode="pessimistic" transform={transform}>
            <SimpleForm
                warnWhenUnsavedChanges
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        e.preventDefault();
                        document.activeElement.blur();
                    }
                }}
                {...additionalProps}
            >
                <Layout />
            </SimpleForm>
        </Wrapper>
    );
};

export default CreateDocument;
