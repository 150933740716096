import React, { useMemo, useState, useEffect, Fragment } from "react";
import Typography from "@mui/material/Typography";
import { Create, SimpleForm, AutocompleteInput, Edit } from "react-admin";
import { useWatch, useFormContext, FormProvider } from "react-hook-form";
import { useQuery } from "react-query";
import CommonTextField from "../../components/CommonTextField";
import { buildDuesTable } from "../../utils";
import { useLocation } from "react-router-dom";
import moment from "moment";
import KEYS, { getPartyAPI, getReceiptAPI } from "../../api";
import SaveButtonToolbar from "../../components/SaveButtonToolbar";

const Inputs = ({ isCreate }) => {
    const form = useFormContext();

    const accountNumber = useWatch({ name: "account_number" });
    const { setValue } = useFormContext();
    const [showOthers, setShowOthers] = useState("");

    const paidAmount = useWatch({
        name: "amount",
    });
    const type = useWatch({
        name: "type",
    });
    const remarks = useWatch({
        name: "remarks",
    });

    // read current location state
    const { state } = useLocation();

    const { data: partyData, refetch: getPartyData } = useQuery(
        [KEYS.GET_PARTY, accountNumber],
        () =>
            getPartyAPI({
                queryParams: {
                    order: "account_number.desc.nullslast",
                    limit: 1,
                    account_number_with_ledger_number: `eq.${accountNumber}`,
                },
            }),
        {
            enabled: false,
        }
    );
    useEffect(() => {
        if (accountNumber) {
            getPartyData();
            getPaymentsData();
        }
    }, []);

    const { data: paymentsData, refetch: getPaymentsData } = useQuery(
        [KEYS.GET_RECEIPT, accountNumber],
        () =>
            getReceiptAPI({
                queryParams: {
                    account_number: `eq.${accountNumber}`,
                    order: "id.asc.nullslast",
                    is_deleted: "eq.false",
                },
            }),
        {
            enabled: false,
        }
    );

    useEffect(() => {
        if (state?.account_number) {
            setValue("account_number", state.account_number);
            getPartyData();
            getPaymentsData();
        }
    }, [state]);

    useEffect(() => {
        if (partyData && partyData.length) {
            const { name } = partyData[0];
            setValue("name", name);
        }
    }, [partyData]);

    const { table } = useMemo(() => {
        if (!paymentsData?.length || !partyData?.length)
            return {
                table: [],
                defaultInterest: 0,
                defaultReceived: 0,
                totalPendingAmount: 0,
            };
        return buildDuesTable({
            paymentsData,
            accountData: partyData[0],
        });
    }, [paymentsData, partyData]);

    const partyDetails = useMemo(() => {
        if (partyData && partyData.length && paymentsData) {
            const { name, number_of_dues, loan_amount, created_at } = partyData[0];

            // get the first index in table where pendingAmount is not 0
            const firstPendingIndex = table.findIndex((item) => item.pendingDueAmount !== 0);

            return [
                {
                    label: "Party Name",
                    value: name,
                },
                {
                    label: "Total Dues",
                    value: number_of_dues,
                },
                {
                    label: "Loan Amount",
                    value: loan_amount,
                },
                {
                    label: "Loan Date",
                    value: moment(created_at).format("DD/MM/YYYY"),
                },
                {
                    label: "Paid Dues",
                    value: firstPendingIndex,
                },
                {
                    label: "Pending Dues",
                    value: number_of_dues - paymentsData.length,
                },
                {
                    label: "Last Receipt Date",
                    value: paymentsData.length ? moment(paymentsData[paymentsData.length - 1].date).format("DD/MM/YYYY") : "N/A",
                },
            ];
        } else {
            return [];
        }
    }, [partyData, paymentsData, table]);

    useEffect(() => {
        // debounce on change
        const timeout = setTimeout(() => {
            if (accountNumber) {
                getPartyData();
                getPaymentsData();
            }
        }, 500);

        return () => clearTimeout(timeout);
    }, [accountNumber]);

    return (
        <FormProvider {...form}>
            <div className="flex w-full  px-6 py-3 bg-background gap-12">
                <div className="flex w-1/2 flex-col">
                    <div className="flex items-center justify-start mt-2">
                        <p className="text-base mr-2  capitalize text-secondary font-semibold">Temporary Receipts Details</p>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Date</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField
                                type="date"
                                className="cursor-pointer w-full"
                                defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                                disabled={!isCreate}
                                register={form.register("date")}
                            />
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Acc. No.</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField
                                type="text"
                                disabled={!isCreate}
                                className="w-full"
                                placeholder="Account Number"
                                register={form.register("account_number")}
                            />
                        </div>
                    </div>
                    <div className="flex items-center mb-0.5">
                        <div className="flex items-center w-1/3">
                            <p className="text-sm  text-primary font-semibold w-full">Amount</p>
                        </div>
                        <div className="flex items-center w-2/3">
                            <CommonTextField type="number" min={0} defaultValue={0} className="w-full" register={form.register("amount")} />
                        </div>
                    </div>
                    {isCreate ? (
                        <Fragment>
                            <div className="flex items-center mb-0.5">
                                <div className="flex items-center w-1/3">
                                    <p className="text-sm  text-primary font-semibold w-full">Type</p>
                                </div>
                                <div className="flex items-center w-2/3">
                                    <AutocompleteInput
                                        className={`auto-complete w-full`}
                                        fullWidth
                                        label={false}
                                        helperText={false}
                                        onChange={(e) => {
                                            if (e === "OTHERS") {
                                                setShowOthers(true);
                                            } else {
                                                setValue("remarks", "");
                                                setShowOthers(false);
                                            }
                                        }}
                                        source="type"
                                        choices={[
                                            { id: "FC", name: "FC" },
                                            { id: "INSURANCE", name: "INSURANCE" },
                                            { id: "PERMIT", name: "PERMIT" },
                                            { id: "OTHERS", name: "OTHERS" },
                                        ]}
                                    />
                                </div>
                            </div>
                            {showOthers && (
                                <div className="flex items-center mb-0.5">
                                    <div className="flex items-center w-1/3">
                                        <p className="text-sm  text-primary font-semibold w-full">Other</p>
                                    </div>
                                    <div className="flex items-center w-2/3">
                                        <CommonTextField
                                            type="text"
                                            placeholder="Specify Type"
                                            className="w-full"
                                            register={form.register("remarks")}
                                        />
                                    </div>
                                </div>
                            )}
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className="flex items-center mb-0.5">
                                <div className="flex items-center w-1/3">
                                    <p className="text-sm  text-primary font-semibold w-full">Amount</p>
                                </div>
                                <div className="flex items-center w-2/3">
                                    <p className="text-sm  text-primary font-semibold w-full">{paidAmount || 0}</p>
                                </div>
                            </div>
                            <div className="flex items-center mb-0.5">
                                <div className="flex items-center w-1/3">
                                    <p className="text-sm  text-primary font-semibold w-full">Type</p>
                                </div>
                                <div className="flex items-center w-2/3">
                                    <p className="text-sm  text-primary font-semibold w-full">{type === "OTHERS" ? remarks : type}</p>
                                </div>
                            </div>
                        </Fragment>
                    )}
                </div>
                <div className="flex w-1/2 flex-col">
                    {partyDetails.map(({ label, value }, index) => (
                        <div className="flex items-center mb-0.5" key={index}>
                            <div className="flex items-center w-1/3">
                                <p className="text-sm  text-primary font-semibold w-full">{label} :</p>
                            </div>
                            <div className="flex items-center w-2/3">
                                <p className="text-sm  text-primary font-semibold w-full">{value}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </FormProvider>
    );
};

const CustomReceiptForm = ({ isCreate, ...props }) => {
    const Wrapper = isCreate ? Create : Edit;
    const additionalProps = isCreate
        ? {}
        : {
              toolbar: <SaveButtonToolbar />,
          };

    const transform = (data) => {
        return { ...data };
    };

    return (
        <Wrapper {...props} actions={Fragment} className="my-2" redirect="show" transform={transform}>
            <SimpleForm
                warnWhenUnsavedChanges
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        e.preventDefault();
                        document.activeElement.blur();
                    }
                }}
                {...additionalProps}
            >
                <Typography variant="h5">Temporary Receipts Details</Typography>
                <Inputs isCreate={isCreate} />
            </SimpleForm>
        </Wrapper>
    );
};

export default CustomReceiptForm;
