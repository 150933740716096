import React from "react";
import { TextInput } from "react-admin";
import Grid from "@mui/material/Grid";

const CustomTextInput = ({ label, ...props }) => {
    return (
        <Grid container direction="row" alignItems="center">
            {label && (
                <Grid item xs={4} style={{ fontSize: "14px" }}>
                    {label}
                </Grid>
            )}

            <Grid item xs={true}>
                <TextInput {...props} helperText={false} label={false} fullWidth />
            </Grid>
        </Grid>
    );
};

export default CustomTextInput;
